import Const from '../const';
import JSON from 'circular-json';
import Page from './page';
//import * as Sentry from '../sentry.init';
import Storage from './storage-shim';
import HandledPromise from './handled-promise';
import grFetch from './fetch';
import { v4 as uuidv4 } from 'uuid';
import Utils from './utils';
import Analytics from "./analytics";
import CollagesPopup from "./vendors/CollagesPopup";
//import Assistant from '../widget-assistant/module';

let Widget = () => {
    let self = {};

    self.apiBaseUri = process.env.API_URL;
    self.appEngineUri = process.env.APP_ENGINE_URL;
    if (!self.appEngineUri)
        self.appEngineUri = self.apiBaseUri;
    self.statUrl = process.env.STAT_URL;
    self.platformUrl = process.env.PLATFORM_URL;
    if (!self.platformUrl)
        self.platformUrl = 'https://platform.modatech.ru/api/content/';

    self.isInit = false;
    self.containerEl = null;
    self.pageOptions = {};

    self.customMainCSSPath = '';
    self.favoritesProductsIds = [];

    self.key = null;
    self.initOptions = {};

    self.isVendorB44 = () => {
        return self.key === '0cf8998b423941deb2f4a1465e1795f0';
    };

    self.getPageSource = () => {
        if (self.pageOptions) {
            if (self.pageOptions.tag_name) {
                return 'tags_' + self.pageOptions.tag_name.toLowerCase();
            } else if (self.pageOptions.code)
                return self.pageOptions.code;
            else
                return self.pageOptions.type;
        }
    }

    self.getKeyPromise = () => {
        return self.keyPromise
            ? self.keyPromise
            : (self.keyPromise = new HandledPromise((resolve, reject) => {
                  let iterations = 0;
                  let retry = setTimeout(function retryKey() {
                      try {
                          clearTimeout(retry);
                          if (self.key) {
                              resolve(self.key);
                          } else if (++iterations <= 40) retry = setTimeout(retryKey, iterations * 10);
                          else throw Error('You must provide API key');
                      } catch (e) {
                          clearTimeout(retry);
                          reject(e);
                      }
                  }, 10);
              }));
    };

    self.page = null;

    let storage = Storage(self);
    self.sessionKey = storage.key(Const.widgetSessionKey);
    self.widgetLastItems = storage.key(Const.widgetLastItemsKey, 'json');
    self.widgetLastItemsIds = storage.key(Const.widgetLastItemsIdsKey, 'json');
    self.widgetParamsLastUpdate = storage.key(Const.widgetParamsLastUpdateKey, 'date');
    self.widgetParams = storage.key(Const.widgetParamsKey, 'json');
    self.widgetABTest = storage.key(Const.widgetABTestKey, 'json');
    self.widgetLangCode = storage.key(Const.widgetLangCode, 'json');
    self.initData = {};

    self._callbacks = {};

    self.user = {};
    self.widgetApi = {};

    self.widgetApi.registerCallback = {};

    self.checkCallback = (callable) => {
        if (!(callable instanceof Function)) {
            try {
                callable = Function(callable);
            } catch (e) {
                throw new Error('You must pass a valid function or function name to registerCallback method');
            }
        }
        return true;
    };

    self.getTransactionId = () => {
        return uuidv4();
    };

    self.widgetApi.registerCallback.fallback = (callable) => {
        if (self.checkCallback(callable)) self._callbacks.fallback = callable;

        return self.widgetApi;
    };

    self.widgetApi.registerCallback.onDataLoaded = (callable) => {
        if (self.checkCallback(callable)) self._callbacks.onDataLoaded = callable;

        return self.widgetApi;
    };

    self.widgetApi.registerCallback.onPlatformDataLoaded = (callable) => {
        if (self.checkCallback(callable)) self._callbacks.onPlatformDataLoaded = callable;

        return self.widgetApi;
    };

    self.widgetApi.registerCallback.pageInit = (callable) => {
        if (self.checkCallback(callable)) self._callbacks.pageInit = callable;

        return self.widgetApi;
    };
    self.widgetApi.registerCallback.showBlock = (callable) => {
        if (self.checkCallback(callable)) self._callbacks.showBlock = callable;

        return self.widgetApi;
    };
    self.widgetApi.registerCallback.productClick = (callable) => {
        if (self.checkCallback(callable)) self._callbacks.productClick = callable;

        return self.widgetApi;
    };
    self.widgetApi.registerCallback.productReaction = (callable) => {
        if (self.checkCallback(callable)) self._callbacks.productReaction = callable;

        return self.widgetApi;
    };
    self.widgetApi.registerCallback.productCart = (callable) => {
        if (self.checkCallback(callable)) self._callbacks.productCart = callable;

        return self.widgetApi;
    };
    self.widgetApi.registerCallback.purchase = (callable) => {
        if (self.checkCallback(callable)) self._callbacks.purchase = callable;

        return self.widgetApi;
    };
    self.widgetApi.registerCallback.addToCart = (callable) => {
        if (self.checkCallback(callable)) self._callbacks.addToCart = callable;

        return self.widgetApi;
    };

    self.widgetApi.registerCallback.addToFavorites = (callable) => {
        if (self.checkCallback(callable)) self._callbacks.addToFavorites = callable;

        return self.widgetApi;
    };
    
    self.widgetApi.registerCallback.addToCartEvent = (callable) => {
        if (self.checkCallback(callable)) self._callbacks.addToCartEvent = callable;

        return self.widgetApi;
    };

    self.widgetApi.registerCallback.openPopup = (callable) => {
        if (self.checkCallback(callable)) self._callbacks.openPopup = callable;

        return self.widgetApi;
    };

    self.widgetApi.registerCallback.addToCartAll = (callable) => {
        if (self.checkCallback(callable)) self._callbacks.addToCartAll = callable;

        return self.widgetApi;
    };

    self.widgetApi.registerCallback.linkClicked = (callable) => {
        if (self.checkCallback(callable)) self._callbacks.linkClicked = callable;

        return self.widgetApi;
    };

    self.widgetApi.registerCallback.subscribeToProduct = (callable) => {
        if (self.checkCallback(callable)) self._callbacks.subscribeToProduct = callable;

        return self.widgetApi;
    };

    self.getSessionPromise = () => {
        let data = self.initData;
        if (self.initOptions.lang_code) {
            data = Object.assign(data || {}, { lang_code: self.initOptions.lang_code });
        }

        if (self.initOptions && self.initOptions.is_platform)
            self.sessionPromise = undefined;

        return self.sessionPromise
            ? self.sessionPromise
            : (self.sessionPromise = new HandledPromise((resolve, reject) => {
                  new HandledPromise((res) => {
                      const sessionParamName = 'garderobo-session-key';
                      const urlParams = new URLSearchParams(window.location.search);
                      if (urlParams.has(sessionParamName)) {
                          const sessionValue = urlParams.get(sessionParamName);
                          if (sessionValue) {
                              self.sessionKey.set(sessionValue).catch(reject);
                          }
                          res(sessionValue);
                      } else {
                          self.sessionKey
                              .get()
                              .then((value) => res(value))
                              .catch(() => res(false));
                      }
                  })
                      .then((session) => {
                          if (self.initOptions && self.initOptions.is_platform && !self.initOptions.is_platform_inited) {
                              reject();
                          } else {
                              if (!session || session === 'undefined') {
                                  self.fetch('start_session/', {
                                      type: 'post',
                                      data: data,
                                      dateType: null,
                                      checkInit: false,
                                  })
                                      .then((d) => {
                                          if (d.hasOwnProperty('session') && d.session) {
                                              self.sessionKey.set(d.session).catch(reject);
                                              resolve(d.session);
                                          } else throw Error('Server did not respond with valid session key');
                                      })
                                      .catch(reject);
                              } else resolve(session);
                          }
                      })
                      .catch(reject);
              }));
    };

    self.getStylePromise = () => {
        return self._stylePromise
            ? self._stylePromise
            : (self._stylePromise = new HandledPromise((resolve, reject) => {
                  self.getParamsPromise()
                      .then(() => {
                          let style = document.createElement('link');
                          style.rel = 'stylesheet';
                          if (self.customMainCSSPath)
                              style.href = self.customMainCSSPath;
                          else
                              style.href = process.env.CSS_BASE_URL + 'widget.css?ver=150324_2';

                          console.log(style.href);
                          console.log(self.customMainCSSPath);

                          let customStyle = null;
                          new HandledPromise((res) => {
                              self.widgetParams
                                  .get()
                                  .then((params) => {
                                      if (params.hasOwnProperty('css_url') && params.css_url) {
                                          customStyle = document.createElement('link');
                                          customStyle.rel = 'stylesheet';
                                          customStyle.href = params.css_url;
                                          if (window.location.hostname.indexOf('keng.ru') !== -1) {
                                              customStyle.href = params.css_url += '?ver=3';
                                          }
                                          res(customStyle);
                                      }
                                  })
                                  .catch(() => res());
                          })
                              .then((customStyle) => {
                                  resolve({
                                      style: style,
                                      customStyle: customStyle,
                                  });
                              })
                              .catch(reject);
                      })
                      .catch(reject);
              }));
    };

    self.getParamsPromise = (manualInit) => {
        return self._paramsPromise && !manualInit
            ? self._paramsPromise
            : (self._paramsPromise = new HandledPromise((resolve, reject) => {
                  self.getSessionPromise()
                      .then(() => {
                          let paramsUpdater = (lastUpdate, isForced) => {
                              if (!lastUpdate) lastUpdate = 0;

                              if (!manualInit && self.initOptions && self.initOptions.is_platform) {
                                 // self.widgetParams.set({"language":"ru", "slider_enabled":true, "currency_sign":" руб.","css_url":"https://widget.garderobo.ru/custom/vipavenue.css"});
                                 // self.widgetParamsLastUpdate.set(new Date());
                                  resolve();
                              } else {
                                  let Now = new Date();
                                  if (Now - lastUpdate > 21600000 || isForced === true) {
                                      // more than 6 hours
                                      console.log('%c Call widget_params', 'color: #0000ff');
                                      self.fetch('widget_params/', {
                                          type: 'post',
                                          data: {lang_code: self.initOptions.lang_code || undefined},
                                          dataType: null,
                                          checkInit: false,
                                      })
                                          .then((data) => {
                                              if (!data) {
                                                  console.log('%c Error getting widget_params', 'color: #ff0000');
                                                  self.widgetApi.stopSession();
                                                  self.reInit();
                                              } else {
                                                  const _data = JSON.parse(data);
                                                  if (manualInit)
                                                      self.page.widgetParams = _data;

                                                  self.widgetParams
                                                      .set(_data)
                                                      .then(() => {
                                                          self.widgetParamsLastUpdate
                                                              .set(Now)
                                                              .then(() => {
                                                                  resolve();
                                                              })
                                                              .catch(reject);
                                                      })
                                                      .catch(reject);

                                                  if (_data.ab_test) {
                                                      window.localStorage.setItem(Const.widgetABTestKeyNew, JSON.stringify(_data.ab_test));
                                                      if (_data.ab_test.condition && _data.ab_test.condition.type === 'scrollTo') {
                                                          self.addABTestScrollEventHandler(_data.ab_test);
                                                      }
                                                  } else {
                                                      window.localStorage.removeItem(Const.widgetABTestKeyNew);
                                                  }
                                              } //else throw Error('Server did not respond with valid widget parameters');
                                          })
                                          .catch(reject);
                                  } else {
                                      self.widgetParams.get().then((params) => {
                                          if (!params) paramsUpdater(null, true);
                                          else resolve();
                                      });
                                  }
                              }
                          };
                          self.widgetParamsLastUpdate
                              .get()
                              .then(paramsUpdater)
                              .catch(() => paramsUpdater(new Date(0)));
                      })
                      .catch(reject);
              }));
    };

    self.reInit = () => {
        // do open_page
        console.log('++++++++++++++++re_init');
        // self._widget = null;
        // self.sessionPromise = null;
        // self._paramsPromise = null;
        //return self.widgetApi.init(self.key);
    };

    self.resetForLangChange = () => {
        self.widgetParams.remove();
        self.widgetParamsLastUpdate.remove();
        self._widget = null;
        self._paramsPromise = null;
        self.isInit = false;
    };

    self.addABTestScrollEventHandler = (ab_test) => {
        const el = document.getElementById(ab_test.condition.id);
        if (el) {
            self.ABTestScrollEventListener = (event) => {
                if (Utils.isElementInViewport(el)) {
                    //console.log('================== in view!');
                    document.removeEventListener('scroll', self.ABTestScrollEventListener);
                    if (typeof gtag === 'function') {
                        const gtagObj = {
                            event_category: 'ecommerce',
                            event_label: ab_test.condition.event,
                        };
                        console.log('_____________sendGtm (gtag)___________', gtagObj);
                        gtag('event', ab_test.condition.event, gtagObj);
                        ab_test.event_sended = true;
                        window.localStorage.setItem(Const.widgetABTestKeyNew, JSON.stringify(ab_test));
                    } else {
                        const gtmEvent = {
                            'event': 'ecommerce',
                            'event_category': 'ecommerce',
                            'event_label': ab_test.condition.event
                        };

                        dataLayer.push(gtmEvent);

                        ab_test.event_sended = true;
                        window.localStorage.setItem(Const.widgetABTestKeyNew, JSON.stringify(ab_test));
                    }
                }
            };

            document.addEventListener('scroll', self.ABTestScrollEventListener);
        }
    };

    self._init = (key, options) => {
        self.key = key;
        self.initOptions = options || {};

        self.analytics = new Analytics(key, self);

        if (!self._widget)
            self._widget = new HandledPromise((resolve, reject) => {
                console.log('Initializing widget');
                //if (self.key) Sentry.configureScope((scope) => scope.setTag('widget_api_key', self.key));

                /*Sentry.addBreadcrumb({
                    category: 'init',
                    message: 'Widget initialized with key: ' + key + ' at location ' + window.location.href,
                    level: Sentry.Severity.Info,
                });*/

                if (!options) options = {};
                let externalDataKeys = ['user_id', 'email', 'phone', 'city'];

                for (let i in externalDataKeys)
                    if (options.hasOwnProperty(externalDataKeys[i])) self.initData[externalDataKeys[i]] = options[externalDataKeys[i]];

                self.getSessionPromise()
                    .then((key) => {
                        console.log('Got session key');
                        for (let i in externalDataKeys)
                            if (self.initData.hasOwnProperty(externalDataKeys[i]))
                                self.user['external_' + externalDataKeys[i]] = self.initData[externalDataKeys[i]];

                        //Sentry.configureScope((scope) => scope.setUser(self.user));

                        /*Sentry.addBreadcrumb({
                            category: 'auth',
                            message:
                                'Authenticated as: ' +
                                JSON.stringify(
                                    Object.assign(self.user, {
                                        session_key: key,
                                    })
                                ),
                            level: Sentry.Severity.Info,
                        });*/

                        //Sentry.configureScope((scope) => scope.setUser(Object.assign(self.user, { session_key: key })));

                        self.getParamsPromise()
                            .then((data) => {
                                console.log('Got widget params');
                                /*Sentry.addBreadcrumb({
                                    category: 'init',
                                    message: 'Params: ' + JSON.stringify(data),
                                    level: Sentry.Severity.Info,
                                });*/

                                self.isInit = true;
                                resolve();
                            })
                            .catch(reject);
                    })
                    .catch(reject);
            });
        else console.log('Ignored an attempt to re-initialise widget');
    };

    // self.widgetApi.init = (key, options) => {
    //     if (options && options.lang_code) {
    //         self.widgetLangCode
    //             .get()
    //             .then((langCode) => {
    //                 console.log('__________lang', langCode);
    //                 if (!langCode) {
    //                     self.widgetLangCode.set(options.lang_code);
    //                 } else {
    //                     if (langCode !== options.lang_code) {
    //                         console.log(`___________switch lang: ${langCode} => ${options.lang_code}`);

    //                         self.resetForLangChange();
    //                         self.widgetLangCode.set(options.lang_code);
    //                     }
    //                 }
    //             })
    //             .catch(() => {
    //                 self.widgetLangCode.set(options.lang_code);
    //             })
    //             .finally(() => {
    //                 self._init(key, options);
    //             });
    //     } else {
    //         self._init(key, options);
    //     }

    //     return self.widgetApi;
    // };

    self.widgetApi.init = (key, options) => {
        if (options && options.lang_code) {
            const langCode = window.localStorage.getItem(Const.widgetLangCode);

            if (!langCode) {
                window.localStorage.setItem(Const.widgetLangCode, options.lang_code);
            } else {
                if (langCode !== options.lang_code) {
                    self.resetForLangChange();
                    window.localStorage.setItem(Const.widgetLangCode, options.lang_code);
                }
            }
        }

        if (options && options.user_id) {
            self.user_id = options.user_id;
        } else {
            if (key == 'c0737cf450fb42d685853399f9699fe3') {
                let user_id = self.widgetApi.getCookie('BITRIX_SM_SALE_UID');
                if (user_id)
                    self.user_id = user_id;
            }
        }

        if (options && options.price_tier)
            self.priceTier = options.price_tier;

        if (options && options.customWidgetsCssPath) {
            self.customMainCSSPath = options.customWidgetsCssPath;
        }

        const _ab_test = window.localStorage.getItem(Const.widgetABTestKeyNew);
        if (_ab_test) {
            try {
                const ab_test = JSON.parse(_ab_test);
                if (ab_test.condition && ab_test.condition.type === 'scrollTo' && !ab_test.event_sended) {
                    self.addABTestScrollEventHandler(ab_test);
                }
            } catch (e) {
                window.localStorage.removeItem(Const.widgetABTestKeyNew);
            }
        }

        self._init(key, options);

        return self.widgetApi;
    };

    // self.widgetApi.initAssistant = (options) => {
    //     if (!self._assistant)
    //         self._assistant = new HandledPromise((resolve, reject) => {
    //             console.log('Initializing assistant');
    //             if (self._widget)
    //                 self._widget.then(() => {
    //                     console.log('Waited till widget is init');
    //                     self.getKeyPromise().then((key) => {
    //                         options.key = key;

    //                         if (self.apiBaseUri) {
    //                             options.apiBaseUri = self.apiBaseUri;
    //                         }

    //                         Sentry.addBreadcrumb({
    //                             category: 'init',
    //                             message: 'Before assistant init with options: ' + JSON.stringify(options),
    //                             level: Sentry.Severity.Info,
    //                         });
    //                         Assistant(options).then(resolve).catch(reject);
    //                     });
    //                 });
    //             else throw Error('Widget is not initialized');
    //         });
    //     else console.log('Ignored an attempt to re-initialise assistant');

    //     return self.widgetApi;
    // };

    self.widgetApi.getCookie = (name) => {
        const cookies = document.cookie.split(';');

        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith(name + '=')) {
                return cookie.substring(name.length + 1);
            }
        }

        return null;
    }

    self.widgetApi.initPage = (containerEl, type, options) => {
        if (!options)
            options = {};

        if (containerEl) {
            containerEl.innerHTML = '';
            self.containerEl = containerEl;
        }

        let oldPopup;
        if (containerEl)
            oldPopup = document.querySelector(`.garderobo-widget-container-for-popup[data-container-id=${containerEl.getAttribute('id')}]`);
        else
            oldPopup = document.querySelector('.garderobo-widget-container-for-popup');

        if (oldPopup)
            oldPopup.remove();

        if (type == 'category')
            self.analytics.sendEventOpenCategory(options['category_id'])
        else if (type == 'favorites') {
            if (self.key == 'c0737cf450fb42d685853399f9699fe3') {
                let urlParams = new URLSearchParams(window.location.search);
                let list_id = urlParams.get('list') || '';
                if (list_id)
                    self.user_id = list_id;
            }
            self.analytics.sendEventOpenPage('favorites');
        } else if (['product', 'custom'].includes(type)) {
        } else
            self.analytics.sendEventOpenPage(type);

        let sessionKey = localStorage.getItem(Const.widgetSessionKey);

        self.pageOptions = options;
        self._page = new HandledPromise((resolve, reject) => {
            console.log('Initializing page');
            if (!options) options = {};

            options.type = type;
            console.log('self._widget', self._widget);
            if (self._widget)
                self._widget.then(() => {
                    console.log('Waited till widget is init');
                    self.getParamsPromise()
                        .then(() => {
                            self.getStylePromise()
                                .then((els) => {
                                    console.log('Waited till styles resolved');
                                    const isDemoStand = window.location.hostname.indexOf('backend.garderobo.ai') !== -1;
                                    const isLocal = window.location.hostname.indexOf('localhost') !== -1;

                                    if (containerEl && !isDemoStand && !isLocal) {
                                        containerEl.insertBefore(els.style, containerEl.parent);

                                        if (els.customStyle) containerEl.insertBefore(els.customStyle, containerEl.parent);
                                    }

                                    if (self.initOptions && self.initOptions.is_platform) {
                                        let params = window.localStorage.getItem(Const.widgetParamsKey);
                                        if (params)
                                            params = JSON.parse(params);
                                        else if (options && options.widget_params)
                                            params = options.widget_params;

                                        self.page = new Page(containerEl, type, self, params);
                                        self.page.init(options);
                                        self.page.draw();

                                        resolve();
                                    } else {
                                        self.widgetParams
                                            .get()
                                            .then((params) => {
                                                /*Sentry.addBreadcrumb({
                                                    category: 'init',
                                                    message: 'Before page ' + type + ' init with options: ' + JSON.stringify(options),
                                                    level: Sentry.Severity.Info,
                                                });*/

                                                self.page = new Page(containerEl, type, self, params);
                                                self.page.init(options);
                                                self.page.draw();

                                                resolve();
                                            })
                                            .catch(reject);
                                    }
                                })
                                .catch(reject);
                        })
                        .catch(reject);
                });
            else throw Error('Widget is not initialized');
        });

        return self.widgetApi;
    };

    self.widgetApi.initCategory = (containerEl, options) => {
        if ((options.hasOwnProperty('category_id') && options.category_id) || (options.hasOwnProperty('yml_id') && options.yml_id))
            self.widgetApi.initPage(containerEl, 'category', options);
        else self.widgetApi.initPage(containerEl, 'main', options);
    };

    self.widgetApi.initBrand = (containerEl, options) => {
        if (options.hasOwnProperty('brand') && options.brand) {
            self.widgetApi.initPage(containerEl, 'brand', options);
        } else {
            self.widgetApi.initPage(containerEl, 'main', options);
        }
    };

    self.widgetApi.initCustom = (containerEl, options) => {
        if (options.hasOwnProperty('code') && options.code) {
            self.widgetApi.initPage(containerEl, 'custom', options);
        } else {
            self.widgetApi.initPage(containerEl, 'main', options);
        }
    };

    self.widgetApi.initInline = (containerEl, options) => {
        self.widgetApi.initPage(containerEl, 'inline', options);
    };

    self.widgetApi.initFavorites = (containerEl, options) => {
        if (options.hasOwnProperty('product_ids') && options.product_ids) {
            self.widgetApi.initPage(containerEl, 'favorites', options);
        } else {
            self.widgetApi.initPage(containerEl, 'main', options);
        }
    };

    self.widgetApi.initProduct = (containerEl, options) => self.widgetApi.initPage(containerEl, 'product', options);

    self.widgetApi.initPlatform = (containerEl, options) => self.widgetApi.initPage(containerEl, 'platform', options);

    self.widgetApi.initCart = (containerEl, options) => self.widgetApi.initPage(containerEl, 'cart', options);

    self.widgetApi.initMain = (containerEl, options) => self.widgetApi.initPage(containerEl, 'main', options);

    self.widgetApi.setCartProducts = (product_ids, cartLink, newTitle) => {
        self.cartProductsIds = product_ids.map(id => String(id));
        self.cartLink = cartLink;

        let sizes = document.querySelectorAll('.garderobo-widget-sizes, .gw-select-size');
        for (let i=0; i < sizes.length; i++) {
            sizes[i].dispatchEvent(new Event('change'));
        }

        if (newTitle) {
            let panel = document.querySelector('.garderobo-widget-look__bottom-panel');
            let cartBtn = panel.querySelector('.garderobo-widget-popup-list-item-text-cart-btn');

            cartBtn.innerHTML = newTitle;

            panel.addEventListener('click', (event) => {
                document.location.href = cartLink;
            });
        }
    }

    self.widgetApi.setFavoritesProducts = (product_ids) => {
        self.favoritesProductsIds = product_ids.map(String);
    }

    self.widgetApi.addToCart = (product_data) => {
        let product_id = product_data;
        if (product_data.product_id)
            product_id = product_data.product_id;

        const pageType = self._page ? self.page.type : 'main';

        self.widgetApi.productAction(product_id, 'cart', {
            page_type: pageType,
        });

        self.analytics.sendEventAddToCart(product_id);

        return self.widgetApi;
    };

    self.cartHandler = (options) => {
        if (self._callbacks.hasOwnProperty('productCart')) {
            self._callbacks.productCart.apply(null, [{ productId: options.product_id }]);
        }

        return () => self.widgetApi.addToCart(options.product_id);
    };

    self.widgetApi.attachCartHandler = (cartEl, options) => {
        if (!cartEl) throw Error('You must pass valid element to attachCartHandler');

        cartEl.removeEventListener('click', self.cartHandler(options));
        cartEl.addEventListener('click', self.cartHandler(options));

        return self.widgetApi;
    };

    self.widgetApi.followVendorProductPage = (options) => {
        if (!options.hasOwnProperty('product_id')) throw Error('You must provide product_id to track user transitions');

        self.widgetApi.productAction(options.product_id, 'follow', {
            page_type: self.page ? self.page.type : 'main',
        });

        return self.widgetApi;
    };

    // @TODO: REFACTOR THIS
    self.widgetApi.registerPurchase = (options) => {
        let products = [];
        if (options.hasOwnProperty('product_ids')) {
            products = options.product_ids.map((productId) => {
                return { yml_id: productId };
            });
        }
        if (options.hasOwnProperty('products')) {
            products = options.products;
        }
        if (options.hasOwnProperty('products_data')) {
            for (var i=0; i < options.products_data.length; i++) {
                products.push({
                    yml_id: options.products_data[i]['product_id'],
                    sku_id: options.products_data[i]['sku_id'],
                    count: options.products_data[i]['count']
                });
            }
        }

        if (Array.isArray(products) && products.length === 0) throw Error('You must provide products to track purchases');

        self.registerTransaction(products);

        return self.widgetApi;
    };

    // @TODO: REFACTOR THIS
    self.registerTransaction = (products) => {
        return new HandledPromise((resolve, reject) => {
            // Validate and prepare products' data
            let validProducts = [];
            for (let key in products) {
                let product = products[key];
                let validProduct = {};

                if (product.hasOwnProperty('yml_id') && product.yml_id) validProduct.yml_id = product.yml_id;
                if (product.hasOwnProperty('count') && product.count) validProduct.count = product.count * 1;
                else validProduct.count = 1;
                if (product.hasOwnProperty('price') && product.price) validProduct.price = product.price;
                if (product.hasOwnProperty('sku_id') && product.sku_id) validProduct.sku_id = product.sku_id;

                validProducts.push(validProduct);
            }

            let transactionId = self.getTransactionId();

            if (self._callbacks.hasOwnProperty('purchase')) {
                self._callbacks.purchase.apply(null, [
                    {
                        products: validProducts,
                        transactionId: transactionId,
                    },
                ]);
            }

            let data = {};
            data.action = 'bought';

            // Fetching source for products

            let sourcePromises = [];
            let transaction_guid = uuidv4().replace(/-/g, '');
            for (let key in validProducts) {
                let product = validProducts[key];
                let productData = product;
                sourcePromises.push(
                    new HandledPromise((res, _) => {
                        self.getSourceForYmlId(product.yml_id).then((s) => {
                            if (s && s.hasOwnProperty('source_page_type')) {
                                productData.source_page_type = s.source_page_type;
                                productData.source_block_type = s.source_block_type;
                            }

                            /*Sentry.addBreadcrumb({
                                category: 'action',
                                message: 'Fetched source for ' + product.yml_id + ' with payload ' + JSON.stringify(productData),
                                level: Sentry.Severity.Info,
                            });*/
                            res(productData);
                        });
                    })
                );

                let count = 1;
                if (product.count)
                    count = parseInt(product.count);
                for (var i=0; i < count; i++) {
                    self.analytics.sendEventPurchase(product.yml_id, transaction_guid);
                }
            }

            Promise.all(sourcePromises).then((resultProducts) => {
                data.products = resultProducts;
                data.transaction_id = transactionId;
                self.fetch('product_action/', {
                    type: 'post',
                    data: data,
                    dataType: 'json',
                })
                    .then(response => {
                        resolve();
                    })
                    .catch(reject);
            });
        });
    };

    // @TODO: REFACTOR THIS
    self.getSourceForYmlId = (yml_id) => {
        return new Promise((resolve, reject) => {
            let sourceGetter = (lastItems, lastItemsIds) => {
                let item_id = lastItemsIds.hasOwnProperty(yml_id) ? lastItemsIds[yml_id] : yml_id;

                if (lastItems && lastItems.hasOwnProperty(item_id)) {
                    return {
                        source_block_type: lastItems[item_id].block,
                        source_page_type: lastItems[item_id].page
                    };
                } else return false;
            };

            self.widgetLastItems
                .get()
                .then((lastItems) => {
                    self.widgetLastItemsIds
                        .get()
                        .then((lastItemsIds) => {
                            let source = sourceGetter(lastItems, lastItemsIds);
                            if (source) resolve(source);
                            // Got lastItemsIds and found
                            else resolve(); // Not found
                        })
                        .catch(() => {
                            let source = sourceGetter(lastItems, {});
                            if (source) resolve(source);
                            // Missing lastItemsIds and found
                            else resolve(); // Not found
                        });
                })
                .catch(() => resolve()); // Missing lastItems and not found
        });
    };

    self.setSourceForItem = (item, source_block_type, source_page_type) => {
        return new HandledPromise((_, reject) => {
            let lastItemsSetter = (lastItems, lastItemsIds) => {
                if (item.hasOwnProperty('yml_ids')) {
                    for (let i in item.yml_ids) lastItemsIds[item.yml_ids[i]] = item.yml_id;
                }

                lastItems[item.yml_id] = {};
                lastItems[item.yml_id].block = source_block_type;
                lastItems[item.yml_id].page = source_page_type;

                //self.widgetLastItems.set(lastItems).catch(reject);
                //self.widgetLastItemsIds.set(lastItemsIds).catch(reject);
            };

            self.widgetLastItems
                .get()
                .then((lastItems) => {
                    self.widgetLastItemsIds
                        .get()
                        .then((lastItemsIds) => {
                            lastItemsSetter(lastItems, lastItemsIds);
                        })
                        .catch(() => lastItemsSetter(lastItems, {}));
                })
                .catch(() => lastItemsSetter({}, {}));
        });
    };

    self.widgetApi.productAction = (product_id, action, data) => {
        return new HandledPromise((resolve, reject) => {
            data = data ? data : {};
            data.yml_id = product_id;
            data.action = action;

            self.getSourceForYmlId(product_id)
                .then((s) => {
                    if (s && s.hasOwnProperty('source_page_type')) {
                        data.source_page_type = s.source_page_type;
                        data.source_block_type = s.source_block_type;
                    }

                    /*Sentry.addBreadcrumb({
                        category: 'action',
                        message: 'Registered ' + action + ' for ' + product_id + ' with payload ' + JSON.stringify(data),
                        level: Sentry.Severity.Info,
                    });*/

                    self.fetch('product_action/', {
                        type: 'post',
                        data: data,
                        dataType: 'json',
                    })
                        .then(response => {
                            resolve();
                        })
                        .catch(reject);
                })
                .catch(() => {});
        });
    };

    self.widgetApi.updateSession = (options) => {
        if (!options) options = {};
        let data = {};

        if (options.email) data.email = options.email;
        if (options.user_id) data.user_id = options.user_id;
        if (options.phone) data.phone = options.phone;

        self.fetch('update_session/', { type: 'post', data: data });
    };

    self.widgetApi.stopSession = () => {
        self.sessionKey.remove();
        self.widgetLastItems.remove();
        self.widgetLastItemsIds.remove();
        self.widgetParams.remove();
        self.widgetParamsLastUpdate.remove();
        self.widgetABTest.remove();
        self.widgetLangCode.remove();
    };

    self.widgetApi.getDataForCreator = () => {
        return {
            key: self.key,
            apiBaseUri: self.apiBaseUri,
            containerEl: self.containerEl,
            options: self.pageOptions,
        };
    };

    self.widgetApi.getDataForNewPopup2 = () => {
        return {
            key: self.key,
            apiBaseUri: self.apiBaseUri,
            containerEl: self.containerEl,
            options: self.pageOptions,
            //
            page: self.page,
        };
    };

    self.fetch = function () {
        if ((arguments[0].includes('start_session')) || (arguments[0].includes('widget_params')))
            arguments[0] = self.appEngineUri + arguments[0];
        else
            arguments[0] = self.apiBaseUri + arguments[0];
        return grFetch.apply(self, arguments);
    };

    return self;
};

export default Widget;

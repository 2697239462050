import {Texts} from "../const";

export default class InlineWidget {
    constructor(blocks, page, texts) {
        this.blocks = blocks;
        this.page = page;
        this.texts = texts;
        this.allLooks = null;
        this.currentIndex = 0;
        this.startX = 0;
        this.endX = 0;
    }

    setLikedState(product, likeButton, lookId) {
        let likeButtonText = likeButton.querySelector('.gw-button-add-favorites-text');

        if (this.page._widget.favoritesProductsIds) {
            if (this.page._widget.favoritesProductsIds.includes(product.group_id + '') || this.page._widget.favoritesProductsIds.includes(product.id + '')) {
                console.log('LIKED -------------------------- ');
                likeButton.classList.add('garderobo-like-button-liked-state');
                likeButtonText.innerHTML = this.page.getText(this.texts.UNFAVORITE);
            }
        }

        likeButton.addEventListener('click', (event) => {
            event.stopPropagation();

            let state = true;
            if (likeButton.classList.contains('garderobo-like-button-liked-state')) {
                state = false;
                likeButton.classList.remove('garderobo-like-button-liked-state');
                likeButtonText.innerHTML = this.page.getText(this.texts.ADD_TO_FAVORITES);
                let index = this.page._widget.favoritesProductsIds.indexOf(product.group_id + '');
                if (index !== -1) {
                    this.page._widget.favoritesProductsIds.splice(index, 1);
                }
            } else {
                likeButton.classList.add('garderobo-like-button-liked-state');
                likeButtonText.innerHTML = this.page.getText(this.texts.UNFAVORITE);
                if (!this.page._widget.favoritesProductsIds.includes(product.group_id + '')) {
                    this.page._widget.favoritesProductsIds.push(product.group_id + '');
                }
            }

            let productId = product.group_id;
            if (product.offers_props_by_yml_id) {
                for (const key in product.offers_props_by_yml_id) {
                    productId = key;
                    break;

                    const value = product.offers_props_by_yml_id[key];
                    if (value && typeof value === "object") {
                        const keys = Object.keys(value);
                        if (keys.length > 0) {
                            productId = value[keys[0]]; // Получаем первое значение
                            break;
                        }
                    }
                }
            }

            this.page._widget._callbacks.addToFavorites.apply(null, [productId, state, product]);
            if (state)
                this.page._widget.analytics.sendEventAction('add_to_favorites', product.wareId, lookId, 'looks_inline', this.page._widget.getPageSource(), product.price, product.old_price);
            else
                this.page._widget.analytics.sendEventAction('remove_from_favorites', product.wareId, lookId, 'looks_inline', this.page._widget.getPageSource(), product.price, product.old_price);
        });
    }

    initInline(blocks) {
        let inlineWidgets = this.page._widget.containerEl;
        let inlineWidgetsContainer = document.createElement('div');
        let gwLeftBlock = document.createElement('div');
        inlineWidgetsContainer.classList.add('gw-container', 'gw-inline');
        gwLeftBlock.classList.add('gw-left-block');

        inlineWidgets.appendChild(inlineWidgetsContainer);

        let gwSlider = document.createElement('div');
        let gwSliderWrapper = document.createElement('div');
        let gwSliderThumbnail = document.createElement('div');

        let gwSliderNextBtn = document.createElement('button');
        gwSliderNextBtn.classList.add('gw-button-slide', 'gw-button-slide-next');
        gwSliderNextBtn.innerHTML = `
            <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.714294 1.14282L7.57144 7.99997L0.714294 14.8571" stroke="#18191B" stroke-width="1.2" stroke-linecap="round"/>
            </svg>
        `

        let gwSliderPrevBtn = document.createElement('button');
        gwSliderPrevBtn.classList.add('gw-button-slide', 'gw-button-slide-prev');
        gwSliderPrevBtn.innerHTML = `
            <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.28577 14.8572L1.42862 8.00003L8.28577 1.14289" stroke="#18191B" stroke-width="1.2" stroke-linecap="round"/>
            </svg>
        `

        gwSliderNextBtn.addEventListener('click', () => {
            this.showNextSlide();
        });

        gwSliderPrevBtn.addEventListener('click', () => {
            this.showPrevSlide();
        });

        let gwProducts = document.createElement('div');
        gwSlider.classList.add('gw-slider');
        gwSliderWrapper.classList.add('gw-slider-wrapper');
        gwSliderThumbnail.classList.add('gw-slider-thumbnail');
        gwProducts.classList.add('gw-products');

        inlineWidgetsContainer.appendChild(gwLeftBlock);
        inlineWidgetsContainer.appendChild(gwProducts);
        gwLeftBlock.appendChild(gwSlider);

        gwSlider.appendChild(gwSliderWrapper);
        gwLeftBlock.appendChild(gwSliderThumbnail);

        gwLeftBlock.appendChild(gwSliderNextBtn);
        gwLeftBlock.appendChild(gwSliderPrevBtn);

        gwSliderWrapper.addEventListener('touchstart', (e) => {
            this.sliderTouchStart(e);
        });

        gwSliderWrapper.addEventListener('touchmove', (e) => {
            this.sliderTouchMove(e);
        });

        gwSliderWrapper.addEventListener('touchend', () => {
            this.sliderTouchEnd();
        });

        const urlParams = new URLSearchParams(window.location.search);
        let look_id = urlParams.get('look_id');
        let currentLookIndex;

        if (look_id) {
            currentLookIndex =  blocks.findIndex(block => block.look_id == look_id);

            if (currentLookIndex !== -1) {
                const [item] = blocks.splice(currentLookIndex, 1);
                blocks.unshift(item);
            }
        }

        this.allLooks = blocks.filter(item => item.type === 'looks');

        blocks
            .filter(item => item.type === 'looks')
            .forEach((item, index) => {
                if (item.products) {
                    let gwSliderItem = document.createElement('div');
                    gwSliderItem.classList.add('gw-slider-item');
                    gwSliderItem.setAttribute('data-look-id', item.look_id);

                    let gwSliderThumbnailItem = document.createElement('div');
                    gwSliderThumbnailItem.classList.add('gw-slider-thumbnail-item');

                    item.products.map(product => {
                        if (product.collage_data) {
                            let img = document.createElement('img');
                            img.src = product.picture;
                            img.classList.add('gw-slider-item-img');
                            img.setAttribute('data-product-id', product.id);
                            img.setAttribute('data-waist-length', product.waist_length);
                            img.setAttribute('data-width', product.collage_data.template_width);
                            img.setAttribute('data-left', product.collage_data.template_left);

                            // set css styles based on the product props
                            img.style.backgroundPositionX = product.collage_data.backgroundPositionX;
                            img.style.backgroundPositionY = product.collage_data.backgroundPositionY;
                            img.style.objectPosition = product.collage_data.backgroundPositionX + ' ' + product.collage_data.backgroundPositionY;
                            img.style.height = `${product.collage_data.height}%`;
                            img.style.left = `${product.collage_data.left}%`;
                            img.style.top = `${product.collage_data.top}%`;
                            img.style.width = `${product.collage_data.width}%`;
                            img.style.zIndex = product.collage_data.zIndex;
                            gwSliderItem.appendChild(img);

                            if (product.is_deleted) {
                                let imgWrapper = document.createElement('div');
                                imgWrapper.classList.add('gw-grid-look__product-disabled-layout');
                                imgWrapper.setAttribute('data-product-id', product.id);
                                imgWrapper.style.height = `${product.collage_data.height}%`;
                                imgWrapper.style.left = `${product.collage_data.left}%`;
                                imgWrapper.style.top = `${product.collage_data.top}%`;
                                imgWrapper.style.width = `${product.collage_data.width}%`;
                                imgWrapper.style.position = 'absolute';

                                img.style.opacity = 0.2;

                                imgWrapper.style.zIndex = 999;
                                imgWrapper.innerHTML = 'НЕТ В НАЛИЧИИ';

                                gwSliderItem.appendChild(imgWrapper);
                            }
                        }
                    })

                    gwSliderWrapper.appendChild(gwSliderItem);

                    let clonedGwSliderItem = gwSliderItem.cloneNode(true);

                    gwSliderThumbnailItem.appendChild(clonedGwSliderItem);

                    gwSliderThumbnailItem.addEventListener('click', () => {
                        this.showSlide(index, item.look_id);
                        this.showProducts(item.products, item.look_id);
                    })

                    if (blocks.length > 1)
                        gwSliderThumbnail.appendChild(gwSliderThumbnailItem);

                    if (blocks.length <= 5 && window.innerWidth >= 768 || blocks.length <= 3 && window.innerWidth <= 500)
                        gwSliderThumbnail.style.justifyContent = 'center';
                }
            })

        this.showSlide(0, blocks[0].look_id);
        this.showProducts(blocks[0].products, blocks[0].look_id);
        
    }

    showSlide(index, lookId) {
        this.currentLookId = lookId;

        const slideItem = document.querySelectorAll('.gw-slider-wrapper .gw-slider-item');
        const totalSlides = slideItem.length;

        const prevButton = document.querySelector('.gw-button-slide-prev');
        const nextButton = document.querySelector('.gw-button-slide-next');

        this.currentIndex = index;

        slideItem.forEach((slide, i) => {
            slide.style.transform = `translateX(-${this.currentIndex * 100}%)`;
        })

        this.currentIndex === 0 ? prevButton.style.display = 'none' : prevButton.style.display = 'flex';
        this.currentIndex === totalSlides - 1 ? nextButton.style.display = 'none' : nextButton.style.display = 'flex';

        let allThumbnailItems = document.querySelectorAll('.gw-slider-thumbnail-item');
        const allThumbnailItemsArray = [...allThumbnailItems];

        let thumbnailContainer = document.querySelector('.gw-slider-thumbnail');

        allThumbnailItemsArray.forEach((thumbnail, i) => {
            if (i === index) {
                thumbnail.classList.add('gw-slider-thumbnail-item-active');

                const thumbnailContainerRect = thumbnailContainer.getBoundingClientRect();
                const thumbnailItemRect = thumbnail.getBoundingClientRect();

                if (thumbnailItemRect.right > thumbnailContainerRect.right) {
                    thumbnailContainer.scrollLeft += (thumbnailItemRect.right - thumbnailContainerRect.right);
                }

                if (thumbnailItemRect.left < thumbnailContainerRect.left) {
                    thumbnailContainer.scrollLeft -= (thumbnailContainerRect.left - thumbnailItemRect.left);
                }
            } else {
                thumbnail.classList.remove('gw-slider-thumbnail-item-active');
            }
        })
    }

    calcCollagePositions(templateLeft, templateWidth, cropWidth, waistLength) {
        let result = {};

        let productWidth = templateWidth * (cropWidth / waistLength)
        if (productWidth > 45)
            productWidth = 45;

        let productLeft = templateLeft + ((templateWidth / 2) - (productWidth / 2));
        if ((productLeft + productWidth) > 100)
            productLeft = 100 - productWidth;

        result['left'] = productLeft;
        result['width'] = productWidth;

        return result;
    }

    showProducts(products, lookId) {
        let self = this;
        let gwProducts = document.querySelector('.gw-products');
        gwProducts.innerHTML = '';

        products.map(product => {
            let newSelecteProductID;

            let gwProductItem = document.createElement('div');
            gwProductItem.classList.add('gw-product-item');
            gwProductItem.setAttribute('data-product-item-id', product.id);

            let gwProductItemImgBlock = document.createElement('div');
            gwProductItemImgBlock.classList.add('gw-product-item-img-block');

            let gwProductLink = document.createElement('a');
            gwProductLink.href = product.link;

            gwProductLink.addEventListener('click', function () {
                self.page._widget.analytics.sendEventWidgetClick(product.wareId, 'looks_inline', 'inline', lookId, product.price, product.old_price);
            })

            let gwProductChangeButton = document.createElement('button');
            gwProductChangeButton.classList.add('gw-button-change-product');
            gwProductChangeButton.innerHTML = this.page.getText(this.texts.CHANGE);

            let gwProductChangeButtonIcon = document.createElement('span');
            gwProductChangeButtonIcon.innerHTML = `
                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 9C2 12.7275 5.0225 15.75 8.75 15.75C10.5425 15.75 12.26 15.045 13.55 13.8L12.425 12.675C11.4725 13.6875 10.145 14.25 8.75 14.25C4.07 14.25 1.73 8.595 5.0375 5.2875C8.345 1.98 14 4.3275 14 9H11.75L14.75 12H14.825L17.75 9H15.5C15.5 5.2725 12.4775 2.25 8.75 2.25C5.0225 2.25 2 5.2725 2 9Z" fill="#222222"/>
                </svg>
            `

            gwProductChangeButton.prepend(gwProductChangeButtonIcon);

            gwProductChangeButton.addEventListener('click', () => {
                let currentSelectedProduct = product;
                let selectedProductItem = document.querySelector(`.gw-product-item[data-product-item-id="${product.id}"]`);
                let selectedProductFromLook = document.querySelectorAll(`.gw-slider-item[data-look-id="${self.currentLookId}"] .gw-slider-item-img[data-product-id="${product.id}"]`);

                this.page.fetchSimilars(product.item_id, product.wareId, { useCrop: 1 })
                    .then((data) => {
                        if (data.products && data.products.length) {
                            let gwProductsList = document.createElement('div');
                            gwProductsList.classList.add('gw-products-list');

                            let selectedProductIndex;
                            if (selectedProductItem) {
                                selectedProductItem.appendChild(gwProductsList);
                                selectedProductIndex = data.products.findIndex(item => item.id === product.wareId);
                            }

                            if (newSelecteProductID) {
                                selectedProductItem = document.querySelector(`.gw-product-item[data-product-item-id="${newSelecteProductID}"]`);
                                selectedProductFromLook = document.querySelectorAll(`.gw-slider-item[data-look-id="${self.currentLookId}"] .gw-slider-item-img[data-product-id="${newSelecteProductID}"]`);
                                selectedProductItem.appendChild(gwProductsList);
                                selectedProductIndex = data.products.findIndex(item => item.id === newSelecteProductID);
                            }

        
                            if (selectedProductIndex > -1 && selectedProductIndex !== 0) {
                                const [matchingItem] = data.products.splice(selectedProductIndex, 1);
                                data.products.unshift(matchingItem);
                            }


                            data.products.forEach(item => {
                                if (!item.is_deleted) {
                                    let gwProductsListItem = document.createElement('div');
                                    gwProductsListItem.classList.add('gw-products-list-item');

                                    let gwProductsListItemImg = document.createElement('img');
                                    gwProductsListItemImg.src = item.picture;

                                    let productPrice = document.createElement('span');
                                    productPrice.innerHTML = this.page.priceFormatter(item.price);
                                    productPrice.classList.add('gw-product-item-price');

                                    let productSizes = document.createElement('ul');
                                    productSizes.classList.add('gw-products-list-item-sizes');
                                    if (item.sizes && item.sizes.length) {
                                        item.sizes.forEach(size => {
                                            let productSizesItem = document.createElement('li');
                                            productSizesItem.innerHTML = size.name;
                                            productSizes.appendChild(productSizesItem);
                                        })
                                    } else {
                                        let productSizesItem = document.createElement('li');
                                        productSizesItem.innerHTML = this.page.getText(this.texts.ONE_SIZE);
                                        productSizes.appendChild(productSizesItem);
                                    }

                                    gwProductsListItem.appendChild(gwProductsListItemImg);
                                    gwProductsListItem.appendChild(productPrice);
                                    gwProductsListItem.appendChild(productSizes);

                                    gwProductsList.appendChild(gwProductsListItem);

                                    gwProductsListItem.addEventListener('click', () => {
                                        if (selectedProductFromLook.length) {
                                            selectedProductFromLook.forEach(function(collageItem) {
                                                let collageImageWrapper = document.querySelector(`.gw-slider-item[data-look-id="${self.currentLookId}"] .gw-grid-look__product-disabled-layout[data-product-id="${currentSelectedProduct.id}"]`);
                                                if (collageImageWrapper) {
                                                    collageImageWrapper.remove();
                                                    collageItem.style.opacity = 1;
                                                }

                                                collageItem.src = item.picture;
                                                collageItem.setAttribute('data-product-id', item.id);

                                                if (item.waist_length) {
                                                    let collagePositions = self.calcCollagePositions(parseFloat(collageItem.getAttribute('data-left')), parseFloat(collageItem.getAttribute('data-width')), item.crop_wh[0], item.waist_length);
                                                    collageItem.style.left = collagePositions['left'] + '%';
                                                    collageItem.style.width = collagePositions['width'] + '%';
                                                }
                                            });
                                        }

                                        let selectedProductItem = document.querySelector(`.gw-product-item[data-product-item-id="${product.id}"]`);
                                        gwProductItem.setAttribute('data-product-item-id', item.id);
                                        newSelecteProductID = item.id;

                                        if (newSelecteProductID) {
                                            selectedProductItem = document.querySelector(`.gw-product-item[data-product-item-id="${newSelecteProductID}"]`);
                                        }


                                        if (selectedProductItem) {
                                            const selectedProductItemProducts = selectedProductItem.querySelector('.gw-products-list');
                                            const selectedProductItemImg = selectedProductItem.querySelector('.gw-product-item-img-block a img');
                                            const selectedProductButtonShowMore = selectedProductItem.querySelector('.gw-product-item-show-more');
                                            const selectedProductItemName = selectedProductItem.querySelector('.gw-product-item-info h2');
                                            const selectedProductItemPrice = selectedProductItem.querySelector('.gw-product-item-info .gw-product-item-price');
                                            const selectedProductItemPriceOld = selectedProductItem.querySelector('.gw-product-item-info .gw-product-item-old-price');
                                            const selectedProductItemPriceMainBlock = selectedProductItem.querySelector('.gw-product-item-info .gw-product-item-price-block');

                                            const selectedProductSizes = selectedProductItem.querySelector('.gw-product-item-info .gw-select-size');
                                            Array.from(selectedProductSizes.options).forEach(option => {
                                                if (option.value) {
                                                    option.remove();
                                                }
                                            });

                                            if (item.sizes && item.sizes.length) {
                                                item.sizes.forEach(size => {
                                                    let selectedProductSizesItem = document.createElement('option');
                                                    selectedProductSizesItem.innerHTML = size.name;
                                                    selectedProductSizesItem.value = size.yml_id;
                                                    selectedProductSizes.appendChild(selectedProductSizesItem);
                                                })
                                            } else {
                                                let selectedProductSizesItem = document.createElement('option');
                                                selectedProductSizesItem.innerHTML = this.page.getText(this.texts.ONE_SIZE);
                                                selectedProductSizesItem.value = item.yml_id;
                                                selectedProductSizes.appendChild(selectedProductSizesItem);
                                            }
                                            selectedProductSizes.selectedIndex = 0;
                                            selectedProductSizes.dispatchEvent(new Event('change'));

                                            selectedProductItemImg.src = item.picture;
                                            selectedProductItemName.innerHTML = item.name;
                                            selectedProductItemPrice.innerHTML = this.page.priceFormatter(item.price);
                                            if (item.old_price && (item.old_price != item.price)) {
                                                selectedProductItemPriceOld.innerHTML = this.page.priceFormatter(item.old_price);
                                                selectedProductItemPriceOld.style.display = 'block';
                                                selectedProductItemPriceMainBlock.classList.add('gw-product-item-price-with-discount-block');
                                            } else {
                                                selectedProductItemPriceOld.style.display = 'none'
                                                selectedProductItemPriceMainBlock.classList.remove('gw-product-item-price-with-discount-block');
                                            }

                                            currentSelectedProduct.id = item.id;
                                            currentSelectedProduct.wareId = item.id;
                                            currentSelectedProduct.name = item.name;
                                            currentSelectedProduct.picture = item.picture;
                                            currentSelectedProduct.price = item.price;
                                            currentSelectedProduct.old_price = item.old_price;
                                            currentSelectedProduct.sizes = item.sizes;
                                            currentSelectedProduct.link = item.link;
                                            currentSelectedProduct.group_id = item.group_id;

                                            selectedProductButtonShowMore.setAttribute('data-link', item.link);

                                            selectedProductItemProducts.remove();
                                        }
                                    })
                                }
                            })
                        }
                    })
            })

            if (product.is_deleted) {
                setTimeout(function() {
                    gwProductChangeButton.click();
                }, 100);
            }

            let gwProductItemInfo = document.createElement('div');
            gwProductItemInfo.classList.add('gw-product-item-info');

            let img = document.createElement('img');
            img.src = product.picture;

            let productTitle = document.createElement('h2');
            productTitle.innerHTML = product.name;

            let productPrice = document.createElement('span');
            productPrice.innerHTML = this.page.priceFormatter(product.price);
            productPrice.classList.add('gw-product-item-price');

            let productOldPrice = document.createElement('span');
            if (product.old_price && (product.old_price != product.price))
                productOldPrice.innerHTML = this.page.priceFormatter(product.old_price);
            productOldPrice.classList.add('gw-product-item-old-price');

            let productPriceDiv = document.createElement('div');
            productPriceDiv.classList.add('gw-product-item-price-block');
            if (product.old_price && (product.old_price != product.price))
                productPriceDiv.classList.add('gw-product-item-price-with-discount-block');

            productPriceDiv.appendChild(productOldPrice);
            productPriceDiv.appendChild(productPrice);

            let productShowMore =  document.createElement('a');
            productShowMore.innerHTML = this.page.getText(this.texts.SHOW_MORE);
            productShowMore.setAttribute('data-link', product.link);
            productShowMore.classList.add('gw-product-item-show-more');
            productShowMore.addEventListener('click', function() {
                window.open(this.getAttribute('data-link'), '_blank');
            });

            let productSizes = document.createElement('select');
            productSizes.classList.add('gw-select-size');

            let defaultSelectSize = document.createElement('option');
            defaultSelectSize.text = this.page.getText(this.texts.SELECT_SIZE);
            defaultSelectSize.value = '';
            defaultSelectSize.disabled = true;
            defaultSelectSize.selected = true;

            productSizes.add(defaultSelectSize);

            if (product.sizes && product.sizes.length) {
                product.sizes.forEach(size => {
                    let option = document.createElement('option');
                    option.value = size.yml_id;
                    option.textContent = size.name;
                    productSizes.add(option);
                });
            } else {
                let option = document.createElement('option');
                option.value = product.yml_id;
                option.textContent = this.page.getText(this.texts.ONE_SIZE);
                productSizes.add(option);
            }

            let productSize;
            productSizes.addEventListener('change', (event) => {
                productSize = event.target.value;
                if (!productSize) {
                    productAddButton.classList.add('gw-button-add-cart-disabled');
                    productAddButton.innerHTML = self.page.getText(this.texts.TO_CART);
                } else {
                    productAddButton.classList.remove('gw-button-add-cart-disabled');
                    productErrorMsg.style.display = 'none';

                    if (self.page._widget.cartProductsIds) {
                        if (self.page._widget.cartProductsIds.includes(productSize + '')) {
                            let btnAlreadyInCartCaption = self.page.getText(Texts.OPEN_CART);
                            productAddButton.classList.add('garderobo-widget-popup-list-item-text-cart-btn-link-state');
                            if (productAddButton.innerHTML != btnAlreadyInCartCaption)
                                productAddButton.setAttribute('data-text', productAddButton.innerHTML);
                            productAddButton.innerHTML = btnAlreadyInCartCaption;
                            if (self.page._widget.cartLink)
                                productAddButton.setAttribute('data-link', self.page._widget.cartLink);
                        } else {
                            if (productAddButton.getAttribute('data-text'))
                                productAddButton.innerHTML = productAddButton.getAttribute('data-text');
                            productAddButton.removeAttribute('data-link');
                            productAddButton.classList.remove('garderobo-widget-popup-list-item-text-cart-btn-link-state');
                        }
                    }
                }
            });

            let productButtons = document.createElement('div');
            productButtons.classList.add('gw-product-item-buttons');

            let productErrorMsg = document.createElement('div');
            productErrorMsg.classList.add('gw-product-item-error');
            productErrorMsg.innerHTML = this.page.getText(this.texts.PLEASE_SELECT_SIZE);
            productErrorMsg.style.display = 'none';

            let productAddButton = document.createElement('button');
            productAddButton.classList.add('gw-button', 'gw-button-add-cart', 'gw-button-add-cart-disabled');
            productAddButton.innerHTML = this.page.getText(this.texts.TO_CART);

            productAddButton.addEventListener('click', (e) => {
                if (e.target.getAttribute('data-link')) {
                    document.location.href = e.target.getAttribute('data-link');
                    return;
                }

                if (productSize) {
                    if (this.page._widget._callbacks.hasOwnProperty('addToCart')) {
                        this.page._widget.analytics.sendEventWidgetClick(productSize.yml_id || productSize, 'looks_inline', 'inline');
                        this.page.ecommerce.addToCartClick(product, product, productSize.yml_id || productSize);
                        this.page._widget._callbacks.addToCart.apply(null, [product.id, productSize.yml_id || productSize]);
                    } else if (this.page._widget._callbacks.hasOwnProperty('addToCartEvent')) {
                        let filterBlockByIndex = self.allLooks.filter((block, index) => index === self.currentIndex);
                        let selectedBlock = filterBlockByIndex[0];

                        for (var i=0; i < selectedBlock.products.length; i++) {
                            if (selectedBlock.products[i].wareId == product.wareId) {
                                let selectedProduct = selectedBlock.products[i];
                                //this.page._widget.analytics.sendEventWidgetClick(product.wareId, 'looks', 'inline');
                                //this.page.ecommerce.addToCartClick(selectedProduct, selectedProduct, productSize.yml_id || productSize);
                                this.page._widget.analytics.sendEventAddToCartFromPopup(product.wareId, 'looks_inline', this.page._widget.getPageSource(), product.price, product.old_price, lookId);
                                this.page._widget._callbacks.addToCartEvent.apply(null, [productSize.yml_id || productSize, selectedProduct]);
                            }
                        }
                    }
                } else {
                    productErrorMsg.style.display = 'block';
                }

            });

            let productAddFav = document.createElement('button');
            productAddFav.classList.add('gw-button', 'gw-button-add-favorites');

            let productAddFavText = document.createElement('span');
            productAddFavText.classList.add('gw-button-add-favorites-text');

            if (productAddFav.classList.contains('garderobo-like-button-liked-state')) {
                productAddFavText.innerHTML = this.page.getText(this.texts.UNFAVORITE);
            } else {
                productAddFavText.innerHTML = this.page.getText(this.texts.ADD_TO_FAVORITES);
            }

            let productAddFavIcon = document.createElement('span');
            productAddFavIcon.innerHTML = `
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.0029 17.4984L12.0029 17.4984L12 17.5L11.9971 17.4984C11.8759 17.4343 10.2227 16.5331 8.60743 15.1182C6.96585 13.6802 5.50014 11.8302 5.5 9.87619C5.50108 8.98107 5.85707 8.12294 6.48984 7.49002C7.1226 6.85711 7.98047 6.50109 8.87528 6.5C10.0196 6.50008 10.9967 6.98982 11.6001 7.79364L12 8.32634L12.3999 7.79364C13.0033 6.98982 13.9804 6.50008 15.1247 6.5C16.0195 6.50109 16.8774 6.85711 17.5102 7.49002C18.143 8.123 18.499 8.98125 18.5 9.87648C18.4997 11.8304 17.0341 13.6803 15.3926 15.1182C13.7773 16.5331 12.1241 17.4343 12.0029 17.4984Z" stroke="#18191B"/>
                </svg>
            `

            productAddFav.appendChild(productAddFavText);
            productAddFav.appendChild(productAddFavIcon);

            this.setLikedState(product, productAddFav, lookId);

            productButtons.appendChild(productAddButton);
            productButtons.appendChild(productAddFav);

            gwProductLink.appendChild(img);
            gwProductItemImgBlock.appendChild(gwProductLink);
            gwProductItemImgBlock.appendChild(gwProductChangeButton);
            gwProductItemInfo.appendChild(productTitle);
            gwProductItemInfo.appendChild(productPriceDiv);
            gwProductItemInfo.appendChild(productShowMore);
            gwProductItemInfo.appendChild(productSizes);
            gwProductItemInfo.appendChild(productButtons);
            gwProductItemInfo.appendChild(productErrorMsg);
            gwProductItem.appendChild(gwProductItemImgBlock);
            gwProductItem.appendChild(gwProductItemInfo);
            gwProducts.appendChild(gwProductItem);
        })
    }

    changeSlide(step) {
        const slideItem = document.querySelectorAll('.gw-slider-wrapper .gw-slider-item');
        const totalSlides = slideItem.length;
        this.currentIndex = (this.currentIndex + step + totalSlides) % totalSlides;

        let filterBlockByIndex = this.allLooks.filter((block, index) => index === this.currentIndex);
        let selectedBlock = filterBlockByIndex[0];

        this.showProducts(selectedBlock.products, selectedBlock.look_id);
        this.showSlide(this.currentIndex, selectedBlock.look_id);
    }
    showNextSlide() {
        this.changeSlide(1);
    }

    showPrevSlide() {
        this.changeSlide(-1);
    }

    sliderTouchStart(e) {
        this.startX = e.touches[0].clientX;
    }

    sliderTouchMove(e) {
        this.endX = e.touches[0].clientX;
    }
    sliderTouchEnd() {
        const slideItem = document.querySelectorAll('.gw-slider-wrapper .gw-slider-item');
        const totalSlides = slideItem.length;
        const diff = this.startX - this.endX;

        if (Math.abs(diff) > 50) {
            if (diff > 0 && this.currentIndex < totalSlides - 1)  {
                this.currentIndex = (this.currentIndex + 1) % totalSlides;
            } else if (diff < 0 && this.currentIndex > 0) {
                this.currentIndex = (this.currentIndex - 1 + totalSlides) % totalSlides;
            }

            let filterBlockByIndex = this.allLooks.filter((block, index) => index === this.currentIndex);
            let selectedBlock = filterBlockByIndex[0];

            this.showProducts(selectedBlock.products, selectedBlock.look_id);
            this.showSlide(this.currentIndex, selectedBlock.look_id);
        }
    }
}
import {BlockShowType, Texts} from "../const";

export default class Platform {
    constructor(page, vendorKey, userId) {
        let self = this;

        this.vendorKey = vendorKey;
        this.userId = userId;
        this.page = page;
        this.options = page._widget.pageOptions;
        if (this.options) {
            this.page_type = this.options.page_type;
            this.isSlider = this.options.is_slider;
            this.customCaption = this.options.custom_caption;
            this.platformSlider = this.options.platform_slider;
        }

        if (!this.platformSlider)
            this.platformSlider = page.getWidgetParam('platform_slider');
        
        this.page_type = this.page.optionsFromWidget.type;
        this.containerEl = page._widget.containerEl;

        this.platformUrl = this.page._widget.platformUrl;
        if (this.options && this.options.page_type == 'favorites_looks')
            this.initFavoritesLooksPage();
        else {
            this.initPage();
            this.initMoreButton();
        }
    }

    addSlider() {
        let self = this;
        
        const allSliders = document.querySelectorAll('.modatech-look-widgets');

        allSliders.forEach(slider => {
            const slides = slider.querySelectorAll('.modatech-look-widget-container');
            const totalSlides = slides.length;
            
            if (totalSlides <= 3) {
                slider.classList.add('modatech-look-widgets-centered');                
            }

            if (totalSlides < 2)
                return false;

            let slideIndex = 0;
            
            const prevBtn = document.createElement('button');
            prevBtn.classList.add('platform-widget-control-left');
    
            const nextBtn = document.createElement('button');
            nextBtn.classList.add('platform-widget-control-right');
            
            slider.appendChild(prevBtn);
            slider.appendChild(nextBtn);

            nextBtn.addEventListener('click', (e) => {
                e.preventDefault();
                e.stopPropagation();
                slideIndex = (slideIndex + 1) % totalSlides
                showSlide(slideIndex)
            })
    
            prevBtn.addEventListener('click', (e) => {
                e.preventDefault();
                e.stopPropagation();
                slideIndex = (slideIndex - 1 + totalSlides) % totalSlides;
                showSlide(slideIndex);
            })


            const showSlide = (index) => {
                slides.forEach((slide, i) => {
                    slide.style.transform = `translateX(-${slideIndex * 100}%)`;
                    slide.classList.remove('active-modatech-slide');
                    if (index == i)
                        slide.classList.add('active-modatech-slide');
                })
    
                const prevButton = slider.querySelector('.platform-widget-control-left');
                const nextButton = slider.querySelector('.platform-widget-control-right');
    
                slideIndex === 0 ? prevButton.style.display = 'none' : prevButton.style.display = 'block';
                slideIndex === totalSlides - 1 ? nextButton.style.display = 'none' : nextButton.style.display = 'block';
    
                if (self.platformSlider && totalSlides > 3 && !window.matchMedia('(max-width: 768px)').matches) {
                    let visibleItems = 3;
                    nextButton.style.display = slideIndex >= totalSlides - visibleItems ? 'none' : 'block';   
                }
    
                if (self.platformSlider && totalSlides <= 3 && !window.matchMedia('(max-width: 768px)').matches) {
                    nextButton.style.display = 'none';
                }
            }

            showSlide(slideIndex);

            // mobile touch events
            let startX = 0;
            let endX = 0;

            slider.addEventListener('touchstart', (e) => {
                startX = e.touches[0].clientX;
            })
    
            slider.addEventListener('touchmove', (e) => {
                endX = e.touches[0].clientX;
                console.log(endX);
            })
    
            slider.addEventListener('touchend', () => {
                const diff = startX - endX;
    
                if (endX && Math.abs(diff) > 50) {
    
                    if (diff > 0 && slideIndex < totalSlides - 1)  {
                        slideIndex = (slideIndex + 1) % totalSlides;
                    } else if (diff < 0 && slideIndex > 0) {
                        slideIndex = (slideIndex - 1 + totalSlides) % totalSlides;
                    }
    
                    showSlide(slideIndex);
                }
    
                endX = 0;
            })
        })
    }

    async getWidgetsContent(tags, url, product_id) {
        let self = this;
        let html = '';
        let data;

        if (url)
            data = await this.fetchData(url, null, 'GET');
        else if (product_id)
            data = await this.fetchData('looks-list/?product_id=' + product_id, null, 'GET');
        else
            data = await this.fetchData('looks-by-tags/?tags=' + tags, null, 'GET');

        if (data.status !== "OK") {
            html = '';
        }

        const looks = data.looks;
        if (data.folder_data) {
            this.folderData = data.folder_data;
            this.folderData.sizes_top = this.folderData.sizes_top.map(item => item.trim());
            this.folderData.sizes_bottom = this.folderData.sizes_bottom.map(item => item.trim());
            this.folderData.sizes_shoes = this.folderData.sizes_shoes.map(item => item.trim());
            html += `<h2 class="gw-folder-title">${this.folderData.title}</h2>`;
        }

        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                // Проверяем, виден ли элемент в области просмотра
                if (entry.isIntersecting) {
                    const collageImage = entry.target.querySelector('img');
                    if (collageImage) {
                        const look = [{"look_id": parseInt(collageImage.getAttribute('data-external-id'))}];
                        self.page._widget.analytics.sendEventView('looks_platform', self.page._widget.getPageSource(), look, undefined, self.page.response);
                    }

                    observer.unobserve(entry.target);  // Останавливаем наблюдение за элементом, если нужно
                }
            });
        }, {
            root: null,  // null — это означает, что используется viewport
            rootMargin: '0px',
            threshold: 1  // Сработает, если элемент будет виден хотя бы на 10%
        });

        html += '<div class="modatech-looks"><div class="modatech-look-widgets">';
        looks.forEach(look => {
            html += `<div class="modatech-look-widget-container">
                    <img style="width: 100%;" src="${look.image_url}" data-id="${look.id}" data-external-id="${look.external_id}" />
                    <button style="display: none" class="gw-view-look-btn" data-external-id="${look.external_id}" />
                 </div>`;
        });
        html += '</div></div>';

        self.containerEl.innerHTML = html;

        if (self.page.getWidgetParam('platform_title')) {
            let modatechWrapper = document.querySelector('.modatech-looks');
            let modatechTitle = document.createElement('h3');
            modatechTitle.classList.add('modatech-look-widgets-title');
            modatechWrapper.insertBefore(modatechTitle, modatechWrapper.firstChild);
        }

        let looksWidgets = document.querySelectorAll('.modatech-look-widget-container');
        looksWidgets.forEach(lookWidget => {
            observer.observe(lookWidget);
        });

        if (self.page._widget._callbacks.hasOwnProperty('onPlatformDataLoaded'))
            self.page._widget._callbacks.onPlatformDataLoaded.apply(null, [looks]);
    }

    getParameterByName(name) {
        const url = new URL(window.location.href);
        const param = url.searchParams.get(name);
        return param ? param : null;
    }

    showWidgetPopup(event, element, lookIds) {
        let self = this;
        
        const container = event.target.parentElement;
        const overlay = document.createElement("div");
        overlay.classList.add("modatech-platform-overlay");
        overlay.innerHTML = '<div class="modatech-platform-loader"></div>';
        container.appendChild(overlay);

        let lookId = element.getAttribute('data-external-id');

        self.page._widget.initOptions.is_platform_inited = true;

        self.page._widget.getSessionPromise()
            .then((key) => {
                self.page._widget.getParamsPromise(true)
                    .then((data) => {
                        self.page._widget.fetch('get_looks/', {
                            type: 'post',
                            data: {"looks_ids": lookIds}
                        }).then((res) => {
                            overlay.remove();

                            let widgetPopupContainer = document.querySelector('.garderobo-widget-container');
                            
                            if (widgetPopupContainer) {
                                let parentElement = widgetPopupContainer.parentElement;
                                if (parentElement.classList.contains('modatech-look-widgets')) {
                                    widgetPopupContainer.remove();
                                }
                            }

                            for (let j = 0; j < res.blocks.length; j++) {
                                let block = res.blocks[j];
                                block.show_type = BlockShowType.COLLAGE_BY_TEMPLATES;
                                block.custom_collage_templates = 'grid-platform';
                                for (let k = 0; k < block.products.length; k++) {
                                    let product = block.products[k];
                                    if (product.source_product && product.source_product.wareId) {
                                        product.prev_picture = product.source_product.picture;
                                    }
                                }
                            }

                            const items = self.page.drawFeedItemsWithCollages(res.blocks, 'looks2', 1, true, res.blocks);

                            const container = document.createElement('div');
                            container.classList.add('garderobo-widget-container');
                            container.appendChild(self.page.collagesPopup.drawLooksPopup(items));

                            element.closest('.modatech-look-widgets').appendChild(container);
                            self.page.collagesPopup.openPopup(parseInt(lookId), null, 'looks_platform');
                        })
                    });
            });
    }

    async initPage() {
        let self = this;

        if (self.containerEl) {
            if (self.options && self.options.tag_name)
                await self.getWidgetsContent(self.options.tag_name);
            else if (self.options && self.options.product_id)
                await self.getWidgetsContent(null, null, self.options.product_id);
            else {
                const urlParamKeys = ['folder_id', 'look_ids', 'tags'];
                for (const key of urlParamKeys) {
                    const urlParam = self.getParameterByName(key);
                    if (urlParam) {
                        const queryParam = `${key}=${urlParam}`;
                        await self.getWidgetsContent(null, `looks-list/?${queryParam}`);

                        let looksContainer = document.querySelector('.modatech-look-widgets');
                        looksContainer.classList.add('gw-look-widgets-shared');
                        break;
                    }
                }
            }
        }

        if (self.isSlider || self.platformSlider)
            this.addSlider();

        let platformLooks = document.querySelectorAll('.modatech-look-widgets');
        platformLooks.forEach(function(looksGroup) {
            if (self.customCaption) {
                const customCaption = document.createElement("div");
                customCaption.innerHTML = self.customCaption;
                customCaption.classList.add('platform-looks-caption');
                looksGroup.insertAdjacentElement('afterend', customCaption);

                let btnBuy = document.querySelector('.garderobo-widget-look__btn-buy');
                if (btnBuy) {
                    btnBuy.addEventListener('click', function() {
                        const currentLook = document.querySelector('.active-modatech-slide img');
                        if (currentLook)
                            currentLook.click();
                    });
                }
            } else {
                if (looksGroup.classList.contains('gw-look-widgets-shared')) {
                    let allLooks = looksGroup.querySelectorAll('.modatech-look-widget-container')
                    allLooks.forEach(look => {
                        let viewLookBtn = look.querySelector('.gw-view-look-btn');
                        viewLookBtn.innerHTML = self.page.getText(Texts.VIEW_LOOK);    
                    })
                }
            }

            looksGroup.removeAttribute('style');
            let looks = looksGroup.querySelectorAll('img');
            let buttons = looksGroup.querySelectorAll('.gw-view-look-btn');

            let looksIds = [];
            for (var i=0; i < looks.length; i++) {
                looksIds.push(looks[i].getAttribute('data-external-id'));
            }

            for (var i=0; i < looks.length; i++) {
                if (self.userId) {
                    const container = looks[i].parentElement;
                    const likeButton = document.createElement("div");
                    likeButton.classList.add('modatech-platform-look-like-state-button');
                    container.appendChild(likeButton);
                }

                let look = looks[i];
                looks[i].addEventListener('click', function (event) {
                    self.showWidgetPopup(event, look, looksIds);
                });

                let viewButton = buttons[i];
                if (viewButton) {
                    viewButton.addEventListener('click', function (event) {
                        self.showWidgetPopup(event, viewButton, looksIds);
                    });
                }
            }
        });

        if (self.userId) {
            let likeButtons = document.querySelectorAll('.modatech-platform-look-like-state-button');
            let subscribeButtons = document.querySelectorAll('.modatech-subscribe-button');

            if (self.options && self.options.page_type == 'favorites_looks') {
                likeButtons.forEach(function (likeButton) {
                    likeButton.classList.add('modatech-platform-look-like-state-button-liked');
                });
            } else if (self.options && self.options.page_type == 'subscriptions') {
                self.loadSubscriptionsPage();
            } else {
                self.updateLooksLikeStates();
                self.updateSubscriptionsStates();
            }

            likeButtons.forEach(function(button) {
                self.onLookLikeBtnClickStates(button);
            });

            subscribeButtons.forEach(function(button) {
                self.onSubscribeBtnClickStates(button);
            });
        }

        let lookImages = document.querySelectorAll('.modatech-look-widget-container > img');
        lookImages.forEach(function(lookImg) {
            lookImg.style.display = 'block';
        });
    }

    async loadSubscriptionsPage() {
        let self = this;

        let bloggers = await this.loadSubscriptions();
        let bloggersIds = [];

        if (bloggers.subscriptions_data) {
            let html = '<div class="modatech-bloggers-list modatech-lk-bloggers-list">';
            for (let i = 0; i < bloggers.subscriptions_data.length; i++) {
                let blogger = bloggers.subscriptions_data[i];
                bloggersIds.push(blogger.id);
                html += `
                    <div class="modatech-bloggers-list-item">
                        <a href="/bloggers/${blogger.slug}/" class="modatech-bloggers-list-item-info">
                            <img src="${blogger.photo_url}" alt="${blogger.name}">
                            <div class="modatech-blogger-title">${blogger.title}</div>
                            <h2>${blogger.name}</h2>
                            <div class="modatech-blogger-bio">${blogger.short_bio}</div>
                        </a>
                        <button class="modatech-subscribe-button modatech-subscribe-button-subscribed-state" data-id="${blogger.id}">${this.page.getText(Texts.UNSUBSCRIBE)}</button>
                    </div>
                `
            }
            html += '</div>';

            html += '<div class="modatech-articles-list modatech-lk-articles-list"><button class="modatech-button-next-page" data-blogger-id="' + bloggersIds.join(',') + '" data-page="articles/">' + self.page.getText(Texts.MORE_ARTICLES) + '</button></div>';

            this.containerEl.innerHTML = html;

            let subscribeButtons = document.querySelectorAll('.modatech-subscribe-button');
            subscribeButtons.forEach(function(button) {
                self.onSubscribeBtnClickStates(button);
            });

            self.initMoreButton();
        }
    }

    async loadArticles(button) {
        let self = this;

        let url = button.getAttribute('data-page');
        let bloggerIds = button.getAttribute('data-blogger-id');
        if (bloggerIds && !url.includes('page='))
            url += '?contributor_ids=' + bloggerIds;

        let articles = await self.fetchData(url, null, 'GET');

        const parser = new DOMParser();
        const doc = parser.parseFromString(articles, 'text/html');
        const items = doc.querySelectorAll('.modatech-articles-list-item');
        items.forEach((item, index) => {
            button.insertAdjacentHTML('beforebegin', item.outerHTML);
        });

        let newButton = doc.querySelector('.modatech-button-next-page');
        if (newButton) {
            button.setAttribute('data-page', newButton.getAttribute('data-page'));
            button.style.display = 'block';
        } else
            button.remove();
    }

    async initMoreButton() {
        let self = this;

        let button = document.querySelector('.modatech-button-next-page');
        if (button) {
            if (button.getAttribute('data-page') == 'articles/')
                self.loadArticles(button);

            button.addEventListener('click', async function () {
                if (button.getAttribute('data-page').includes('articles'))
                    self.loadArticles(button);
                else
                    self.initFavoritesLooksPage(button.getAttribute('data-page'));
            });
        }
    }

    async initFavoritesLooksPage(url) {
        let looks = await this.getFavoritesLooks(null, url);
        if (looks && looks.results) {
            let html = '';
            for (let i = 0; i < looks.results.length; i++) {
                let look = looks.results[i];
                html += '<div class="modatech-look-widget-container"><img data-id="' + look.id + '" data-external-id="' + look.external_id + '" src="' + look.image_url + '" /></div>';
            }

            let looksContainer = document.querySelector('.modatech-look-widgets');
            if (looksContainer) {
                looksContainer.innerHTML += html;
                let nextButton = document.querySelector('.modatech-button-next-page');
                if (nextButton) {
                    if (looks.next)
                        nextButton.setAttribute('data-page', looks.next);
                    else
                        nextButton.remove();
                }
            } else {
                html = '<div class="modatech-look-widgets modatech-lk-look-widgets">' + html + '</div>';
                if (looks.next)
                    html += '<button class="modatech-button-next-page" data-page="' + looks.next + '">Загрузить еще</button>';
                this.containerEl.innerHTML = html;
                this.initMoreButton();
            }
        }
        this.initPage();
    }

    async getFavoritesLooks(lookIds, url) {
        if (!url)
            url = 'shopper/favorite-looks/';
        if (lookIds)
            url += '?look_ids=' + lookIds.join(',') + '&page_size=100';

        return this.fetchData(url, null, 'GET');
    }

    async loadSubscriptions() {
        let url = 'shopper/subscription/';

        let subscriptions = this.fetchData(url, null, 'GET');
        return subscriptions;
    }

    async updateSubscriptionsStates() {
        let subscribeButtonsContainers = document.querySelectorAll('.modatech-subscribe-button');
        if (subscribeButtonsContainers.length) {
            let subscriptions = await this.loadSubscriptions();
            for (let i=0; i < subscriptions.subscriptions_data.length; i++) {
                let subscriptionButton = document.querySelector('.modatech-subscribe-button[data-id="' + subscriptions.subscriptions_data[i].id + '"]');
                if (subscriptionButton) {
                    subscriptionButton.classList.add('modatech-subscribe-button-subscribed-state');
                    subscriptionButton.innerHTML = this.page.getText(Texts.UNSUBSCRIBE);
                }
            }
            subscribeButtonsContainers.forEach(function(button) {
                button.classList.remove('modatech-subscribe-button-hide-state');
            });
        }
    }

    async updateLooksLikeStates() {
        let looksContainers = document.querySelectorAll('.modatech-look-widget-container img');
        let ids = Array.from(looksContainers).map(img => img.getAttribute('data-id'));

        if (ids.length) {
            let looks = await this.getFavoritesLooks(ids);
            if (looks && looks.results) {
                for (let i = 0; i < looks.results.length; i++) {
                    let look = looks.results[i];
                    console.log(look);
                    let lookBlocks = document.querySelectorAll('.modatech-look-widget-container img[data-id="' + look.id + '"]');
                    lookBlocks.forEach(function (look) {
                        look.parentNode.querySelector('.modatech-platform-look-like-state-button').classList.add('modatech-platform-look-like-state-button-liked');
                    });
                }
            }
        }
    }

    async onLookLikeBtnClickStates(button) {
        let self = this;

        button.addEventListener('click', function() {
            let lookId = button.parentNode.querySelector('img').getAttribute('data-id');

            let fetchMethod = 'POST';
            if (button.classList.contains('modatech-platform-look-like-state-button-liked')) {
                button.classList.remove('modatech-platform-look-like-state-button-liked');
                fetchMethod = 'DELETE';
            } else {
                button.classList.add('modatech-platform-look-like-state-button-liked');
            }

            self.fetchData('shopper/favorite-looks/', JSON.stringify({look_id: lookId}), fetchMethod);
        });
    }

    async onSubscribeBtnClickStates(button) {
        let self = this;

        button.addEventListener('click', function() {
            let bloggerId = button.getAttribute('data-id');

            let fetchMethod = 'POST';
            if (button.classList.contains('modatech-subscribe-button-subscribed-state')) {
                button.classList.remove('modatech-subscribe-button-subscribed-state');
                button.innerHTML = self.page.getText(Texts.SUBSCRIBE);
                fetchMethod = 'DELETE';
            } else {
                button.classList.add('modatech-subscribe-button-subscribed-state');
                button.innerHTML = self.page.getText(Texts.UNSUBSCRIBE);
            }

            self.fetchData('shopper/subscription/', JSON.stringify({contributor_id: bloggerId}), fetchMethod);
        });
    }

    async fetchData(url, body, method) {
        let self = this;

        const fetchSettings = {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'X-Api-Key': self.vendorKey,
                'Authorization': 'Bearer ' + self.userId
            }
        };

        if (body)
            fetchSettings['body'] = body;

        if (!url.startsWith('http'))
            url = self.platformUrl + url;

        try {
            const response = await fetch(url, fetchSettings);
            if (response.ok) {
                const contentType = response.headers.get('content-type');
                if (contentType && contentType.includes('application/json'))
                    return response.json();
                else if (contentType && contentType.includes('text/html'))
                    return response.text();
                else
                    return null;
            } else {
                response.text().then(errorText => {
                    //console.error(errorText);
                    return null;
                });
            }
        } catch(e) {
            return null;
        }
    }
}
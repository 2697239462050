import { WidgetType } from '../const';
import Utils from './utils';
import JSON from "circular-json";

const GA4Events = {
    VIEW_PROMO: 'view_promotion',
    SELECT_PROMO: 'select_promotion',
    VIEW_ITEM_LIST: 'view_item_list',
    SELECT_ITEM: 'select_item',
};

export default class Ecommerce {
    constructor(vendorKey, page) {
        this.vendorKey = vendorKey;
        this.page = page;
        this.handlers = {
            // '2fa962b29f524e0bbae6439ae8d567a1': {
            //     productClick: () => {
            //         console.log('productClick click for 2fa962b29f524e0bbae6439ae8d567a1');
            //     },
            // },
        };
    }

    /**
     * вызывается при начальной отрисовке виджета "слайдер луков"
     * @param {*} items - массив луков
     * @param {*} selfType
     * @param {*} blockType
     */
    impressionsLook(looks, items, selfType, blockType) {
        if (this.handlers[this.vendorKey] && this.handlers[this.vendorKey].impressionsLook) {
            return this.handlers[this.vendorKey].impressionsLook();
        }

        const promotions = items.map((item) => {
            return {
                id: this.getGtmIdParam(item) || '1.0.0',
                name: Utils.escapeHtml(`Garderobo - ${selfType} ${blockType}`),
                creative: `${item.name} (${item.id})`,
                //position: `${this.page.type} (${elementIndex} item)`,
            };
        });
        const ecommerce = {
            promoView: {
                promotions: promotions,
            },
        };
        this.sendGtm(ecommerce, 'Promotion Impressions');

        const ecommerceNew = {
            currencyCode: this.getCurrencyCode(),
            impressions: items.map((item) => ({
                name: Utils.escapeHtml(item.name),
                id: this.getGtmIdParam(item) || item.id,
                price: item.price,
                brand: item.brand,
                category: item['category-name'],
                //variant: item.color,
                list: `Garderobo - ${selfType} ${blockType}`,
                //position: elementIndex,
            })),
        };
        this.sendGtm(ecommerceNew, 'Product Impressions', 'True');

        //this.page._widget.analytics.sendEventAction('view_widget', blockType);
        for (var i=0; i < looks.length; i++) {
            let look = looks[i];
            this.ga4ViewOrSelectPromotionVer2(looks[i].products, blockType, selfType, looks[i].look_id, looks[i].name, false, GA4Events.VIEW_ITEM_LIST);
            this.ga4ViewOrSelectPromotionVer2(looks[i].products, blockType, selfType, looks[i].look_id, looks[i].name, false, GA4Events.VIEW_PROMO);
        }
    }

    /**
     * вызывается при начальной отрисовке и пролистывании виджета "слайдер продуктов"
     * @param {*} products
     * @param {*} selfType
     * @param {*} blockType
     * @param {*} sentIds
     */
    impressions(products, selfType, blockType, sentIds, lookId) {
        if (this.handlers[this.vendorKey] && this.handlers[this.vendorKey].impressions) {
            return this.handlers[this.vendorKey].impressions();
        }

        const promotions = products.map((item) => {
            let elementIndex = 1;
            sentIds.forEach((id, index) => {
                if (id === item.yml_id) {
                    elementIndex = index + 1;
                }
            });

            return {
                id: this.getGtmIdParam(item) || '1.0.0',
                name: Utils.escapeHtml(`Garderobo - ${selfType} ${blockType}`),
                creative: `${item.name} (${item.id})`,
                position: `${this.page.type} (${elementIndex} item)`,
            };
        });
        const ecommerce = {
            promoView: {
                promotions: promotions,
            },
        };
        this.sendGtm(ecommerce, 'Promotion Impressions');

        //=================>
        if (this.handlers[this.vendorKey] && this.handlers[this.vendorKey].productImpressions) {
            return this.handlers[this.vendorKey].productImpressions();
        }

        const ecommerceNew = {
            currencyCode: this.getCurrencyCode(),
            impressions: products.map((product) => {
                let elementIndex = 1;
                sentIds.forEach((id, index) => {
                    if (id === product.yml_id) {
                        elementIndex = index + 1;
                    }
                });
                return {
                    name: Utils.escapeHtml(`${product.name} ${product.vendor_code}`),
                    id: this.getGtmIdParam(product) || product.vendor_code,
                    price: product.price,
                    brand: product.brand,
                    category: product.category_id,
                    variant: product.color,
                    list: `Garderobo - ${selfType} ${blockType}`,
                    position: elementIndex,
                };
            }),
        };
        this.sendGtm(ecommerceNew, 'Product Impressions', 'True');

        products.forEach((item) => {
            let elementIndex = 1;
            sentIds.forEach((id, index) => {
                if (id === item.yml_id) {
                    elementIndex = index + 1;
                }
            });

            // todo - нужно ли вообще это ga - откуда оно берется??????
            if (typeof ga === 'function') {
                ga('ec:addImpression', {
                    id: this.getGtmIdParam(item) || item.vendor_code,
                    name: Utils.escapeHtml(`${item.name} ${item.vendor_code}`),
                    category: item.category_id,
                    brand: item.brand,
                    variant: item.color,
                    list: `Garderobo - ${selfType} ${blockType}`,
                    position: elementIndex,
                });
                ga('ec:addPromo', {
                    id: this.getGtmIdParam(item) || item.vendor_code,
                    name: Utils.escapeHtml(`${item.name} ${item.vendor_code}`),
                    creative: `Garderobo - ${selfType} ${blockType}`,
                    position: elementIndex,
                });
            }
        });

        //=========>
        console.log('place aa');
        if (lookId)
            blockType = 'looks_horizontal';
        this.page._widget.analytics.sendEventViewProducts(blockType, selfType, products, lookId);

        //this.page._widget.analytics.sendEventAction('view_widget', blockType);
        this.ga4ViewOrSelectPromotionVer2(products, blockType, selfType, lookId, blockType, !lookId, GA4Events.VIEW_ITEM_LIST);
        this.ga4ViewOrSelectPromotionVer2(products, blockType, selfType, lookId, blockType, !lookId, GA4Events.VIEW_PROMO);
        //=========>
        //this.ga4ViewItemList(products, WidgetType.RECOMMEND);
    }

    /**
     * Клик по луку в блоке "слайдер луков"
     * @param {*} look
     */
    lookClick(look, lookIndex) {
        try {
            if (this.handlers[this.vendorKey] && this.handlers[this.vendorKey].lookClick) {
                return this.handlers[this.vendorKey].lookClick();
            }

            const mainProduct = look.products.find((product) => product.wareId === look.main_product_id);
            const ecommerce = {
                promoClick: {
                    promotions: [
                        {
                            id: look.look_id,
                            name: `Garderobo - product looks`,
                            creative: `${mainProduct ? mainProduct.name : 'Главный продукт не определен'} (${look.main_product_id})`,
                        },
                    ],
                },
            };

            this.sendGtm(ecommerce, 'Promotion Clicks');
            this.ga4ViewOrSelectPromotion([{...look, index: lookIndex}], GA4Events.SELECT_PROMO);
            this.ga4ViewOrSelectPromotion([{...look, index: lookIndex}], GA4Events.SELECT_ITEM);
        } catch(e) {
            console.log(e);
        }
    }

    /**
     * Клик (click, auxclick) по продукту в виджете "слайдер продуктов"
     * @param {*} item
     * @param {*} type
     * @param {*} selfType
     * @param {*} elementIndex
     */
    productClick(item, type, selfType, elementIndex, look_id) {
        try {
            if (this.handlers[this.vendorKey] && this.handlers[this.vendorKey].productClick) {
                return this.handlers[this.vendorKey].productClick();
            }

            const ecommerce = {
                promoClick: {
                    promotions: [
                        {
                            id: this.getGtmIdParam(item) || '1.0.0',
                            name: Utils.escapeHtml(`Garderobo - ${selfType} ${type}`),
                            creative: `${item.name} (${item.vendor_code})`,
                            position: `${type} (${elementIndex} item)`,
                        },
                    ],
                },
            };
            console.log('Place 12')
            this.sendGtm(ecommerce, 'Promotion Clicks');

            //if (type != 'looks') {
            let id = item.id;
            if (look_id)
                id = look_id;

            this.ga4ViewOrSelectPromotionVer2([item], type, selfType, id, type, false, GA4Events.SELECT_PROMO);
            this.ga4ViewOrSelectPromotionVer2([item], type, selfType, id, type, false, GA4Events.SELECT_ITEM);
            //}

            // todo - нужно ли вообще это ga - откуда оно берется??????
            if (typeof ga === 'function') {
                ga('ec:addProduct', {
                    id: this.getGtmIdParam(item) || item.vendor_code,
                    name: Utils.escapeHtml(`${item.name} ${item.vendor_code}`),
                    category: item.category_id,
                    brand: item.brand,
                    variant: item.color,
                    position: elementIndex,
                    //'dimension1': 'Member'
                });

                ga('ec:setAction', 'click', {
                    list: `Garderobo - ${selfType} ${type}`,
                });

                ga('ec:addPromo', {
                    id: this.getGtmIdParam(item) || item.vendor_code,
                    name: Utils.escapeHtml(`${item.name} ${item.vendor_code}`),
                    creative: `${item.name} (${item.vendor_code})`,
                    position: elementIndex,
                });

                ga('ec:setAction', 'promo_click');
                ga('send', 'event', 'Internal Promotions', 'click', 'Summer Sale');
            }

            const ecommerceNew = {
                currencyCode: this.getCurrencyCode(),
                click: {
                    actionField: {
                        list: `Garderobo - ${selfType} ${type}`,
                    },
                    products: [
                        {
                            name: Utils.escapeHtml(`${item.name} ${item.vendor_code}`),
                            id: this.getGtmIdParam(item) || item.vendor_code,
                            price: item.price,
                            brand: item.brand,
                            category: item.category_id,
                            variant: item.color,
                            list: type,
                            position: elementIndex,
                        },
                    ],
                },
            };

            this.sendGtm(ecommerceNew, 'Product Clicks');
            this.ga4SelectItem([{...item, index: elementIndex - 1}], WidgetType.RECOMMEND); // check - index
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Клик по продукту в попапе
     * @param {*} product
     */
    productClickPopup(product, look) {
        if (this.handlers[this.vendorKey] && this.handlers[this.vendorKey].productClickPopup) {
            return this.handlers[this.vendorKey].productClickPopup();
        }

        const ecommerce = {
            promoClick: {
                promotions: [
                    {
                        id: this.getGtmIdParam(product) || '1.0.0',
                        name: Utils.escapeHtml(`Garderobo - product looks`),
                        creative: `${product.name} (${product.id})`,
                    },
                ],
            },
        };
        this.sendGtm(ecommerce, 'Product Clicks');

        const ecommerceNew = {
            currencyCode: this.getCurrencyCode(),
            click: {
                actionField: {
                    list: 'Garderobo - product looks',
                },
                products: [
                    {
                        name: Utils.escapeHtml(product.name),
                        id: this.getGtmIdParam(product) || product.id,
                        price: product.price,
                        brand: product.brand,
                        category: product['category-name'],
                        list: `Garderobo - product looks`,
                    },
                ],
            },
        };
        this.sendGtm(ecommerceNew, 'Product Clicks');
        const index = look && look.products ? look.products.findIndex((_product) => _product.id === product.id) : 0;
        const indexedProduct = { ...product, index };

        console.log('Place 13')
        this.ga4ViewOrSelectPromotionVer2([product], 'looks', 'product', look.look_id, look.name, false, GA4Events.SELECT_PROMO);
        this.ga4ViewOrSelectPromotionVer2([product], 'looks', 'product', look.look_id, look.name, false, GA4Events.SELECT_ITEM);
        //this.ga4SelectItem([indexedProduct], WidgetType.LOOKS);
    }

    /**
     * Клик по кнопке "В корзину" на форме сайта
     * @param {*} product
     * @param {*} size - yml_id размера (если есть размер)
     */
    addToCartClick(product, block, size = null) {
        console.log('addToCartClick');
        this.ga4ViewOrSelectPromotionVer2([product], block.type, 'product', block.look_id, block.name, false, GA4Events.SELECT_PROMO, size);
        this.ga4ViewOrSelectPromotionVer2([product], block.type, 'product', block.look_id, block.name, false, GA4Events.SELECT_ITEM, size);

        if (this.handlers[this.vendorKey] && this.handlers[this.vendorKey].addToCartClick) {
            const index = block && block.products ? block.products.findIndex((_product) => _product.id === product.id) : 0;
            const indexedProduct = { ...product, index };

            console.log('Place 14');
            //this.ga4ViewOrSelectPromotion([indexedProduct], GA4Events.SELECT_PROMO);
            //this.ga4SelectItem([indexedProduct], WidgetType.RECOMMEND, size);

            console.log('ga4SelectItem');

            return this.handlers[this.vendorKey].addToCartClick();
        }

        // todo - сейчас никакие данные не передаются в ecommerce
    }

    /**
     * Клик по кнопке "В корзину" в попапе
     * @param {*} product
     * @param {*} size - yml_id размера (если есть размер)
     */
    addToCartClickPopup(product, look, size = null) {
        if (this.handlers[this.vendorKey] && this.handlers[this.vendorKey].addToCartClickPopup) {
            return this.handlers[this.vendorKey].addToCartClickPopup();
        }

        const data = {
            currencyCode: this.getCurrencyCode(),
            click: {
                actionField: {
                    list: 'Garderobo - product looks',
                },
                products: [
                    {
                        name: Utils.escapeHtml(product.name),
                        id: this.getGtmIdParam(product) || product.id,
                        price: product.price,
                        brand: product.brand,
                        category: product['category-name'],
                        list: `Garderobo - product looks`,
                    },
                ],
            },
        };

        this.sendGtm(data, 'Product Clicks');

        const index = look && look.products ? look.products.findIndex((_product) => _product.id === product.id) : 0;
        const indexedProduct = { ...product, index };
        console.log('place 15');

        this.ga4ViewOrSelectPromotionVer2([product], 'looks', 'product', look.look_id, look.name, false, GA4Events.SELECT_PROMO, size);
        this.ga4ViewOrSelectPromotionVer2([product], 'looks', 'product', look.look_id, look.name, false, GA4Events.SELECT_ITEM, size);
        //this.ga4SelectItem([indexedProduct], WidgetType.LOOKS, size);
    }

    /**
     * просмотр лука в попапе
     * @param {*} look
     */
    impressionsPopup(look, lookIndex) {
        if (this.handlers[this.vendorKey] && this.handlers[this.vendorKey].impressionsPopup) {
            return this.handlers[this.vendorKey].impressionsPopup();
        }

        const ecommerce = {
            currencyCode: this.getCurrencyCode(),
            impressions: look.products.map((product) => ({
                name: Utils.escapeHtml(product.name),
                id: this.getGtmIdParam(product) || product.id,
                price: product.price,
                brand: product.brand,
                category: product['category-name'],
                //variant: product.color,
                list: `Garderobo - product looks`,
                //position: 1,
            })),
        };
        this.sendGtm(ecommerce, 'Product Impressions');
        //this.ga4ViewOrSelectPromotion([{ ...look, index: lookIndex }], GA4Events.VIEW_PROMO);
        //this.ga4ViewItemList(look.products, WidgetType.LOOKS);
    }

    /**
     * пролистывание лука в попапе
     * @param {*} look
     * @param {number} dir
     */
    slidePopup(look, dir) {
        if (this.handlers[this.vendorKey] && this.handlers[this.vendorKey].slidePopup) {
            return this.handlers[this.vendorKey].slidePopup();
        }

        this.lookClick(look);
        this.impressionsPopup(look);
        this.sendGtm(undefined, 'select::arrow', 'False', {
            eventCategory: 'Garderobo',
            event: 'uaevent',
            eventLabel: dir > 0 ? '>' : '<',
        });
    }

    /**
     * вызывается при изменении размера в попапе
     * @param {*} product
     * @param {*} sizeValue - name размера
     */
    sizeChangePopup(product, sizeValue) {
        if (this.handlers[this.vendorKey] && this.handlers[this.vendorKey].sizeChangePopup) {
            return this.handlers[this.vendorKey].sizeChangePopup();
        }

        this.sendGtm(undefined, 'select::size', 'False', {
            eventCategory: 'Garderobo',
            event: 'uaevent',
            eventLabel: `${product.group_id || product.id}::${sizeValue}`,
        });
    }

    /**
     * Замена товара в попапе
     * @param {*} look
     * @param {*} product
     * @param {*} replacer
     */
    replaceProductPopup(look, product, replacer) {
        if (this.handlers[this.vendorKey] && this.handlers[this.vendorKey].replaceProductPopup) {
            return this.handlers[this.vendorKey].replaceProductPopup();
        }

        const ecommerceLookData = {
            promoView: {
                promotions: look.products.map((product) => ({
                    id: look.look_id,
                    name: `Garderobo - product looks`,
                    creative: `${product.name} (${product.id})`,
                    //position: `${type} (${elementIndex} item)`,
                })),
            },
        };
        this.sendGtm(ecommerceLookData, 'Promotion Impressions');

        const ecommerceLookDataList = {
            currencyCode: this.getCurrencyCode(),
            impressions: look.products.map((product) => ({
                name: Utils.escapeHtml(product.name),
                id: this.getGtmIdParam(product) || product.id,
                price: product.price,
                brand: product.brand,
                category: product['category-name'],
                //variant: product.color,
                list: `Garderobo - product looks`,
                //position: 1,
            })),
        };
        this.sendGtm(ecommerceLookDataList, 'Product Impressions');

        this.sendGtm(undefined, 'select::replace', 'False', {
            eventCategory: 'Garderobo',
            event: 'uaevent',
            eventLabel: `${product.group_id || product.id}::${replacer.group_id || replacer.id}`,
        });

        const index = look && look.products ? look.products.findIndex((_product) => _product.id === product.id) : 0;
        const indexedProduct = { ...product, index };
        this.ga4ViewItemList([indexedProduct], WidgetType.LOOKS);
    }

    /** google analytics 4 -
     * 1) Виджет попал в область видимости пользователя при скроле страницы
     * 2) При пролистывании товаров/луков
     */
    ga4ViewOrSelectPromotion(items, event, widgetType = null) {
        let promoId, promoName;

        if (!widgetType)
            widgetType = items && items[0] && items[0].look_id ? WidgetType.LOOKS : WidgetType.RECOMMEND;

        switch (widgetType) {
            case WidgetType.RECOMMEND: {
                promoId = 'garderobo_recommendations';
                promoName = 'Garderobo - recommendations widget';
                break;
            }
            case WidgetType.LOOKS: {
                promoId = 'garderobo_looks';
                promoName = 'Garderobo - product looks';
                break;
            }
        }

        const dataList = items.map((item, i) => ({
            promotion_id: promoId,
            promotion_name: promoName,
            creative_name: item.name,
            creative_slot: (item.index || i) + 1,
            location_id: 'product_page',
        }));

        this.sendGtmGA4(dataList, event);
    }

    preparePromotionDetails(id, name, creativeName, creativeSlot, locationId, items, event) {
        let currency = this.getCurrencyCode();
        if (this.getSettings().currency)
            currency = this.getSettings().currency;

        if ((event == GA4Events.VIEW_PROMO) || (event == GA4Events.SELECT_PROMO)) {
            return {
                promotion_id: id,
                promotion_name: name,
                item_list_id: id + '_list',
                item_list_name: name + ' list',
                creative_name: creativeName,
                creative_slot: creativeSlot,
                location_id: locationId,
                currency: currency,
                items: items
            }
        } else {
            return {
                item_list_id: id + '_list',
                item_list_name: name + ' list',
                location_id: locationId,
                currency: currency,
                items: items
            }
        }
    }

    saveDataToLocalStorage(itemId, promoName, promoId, pageType, widgetType, price) {
        const promoHistoryJSON = localStorage.getItem('_garderoboPromoHistory');
        let promoHistory;

        let tmpPageType = self.pageType;
        if (!tmpPageType)
            tmpPageType = pageType;

        let data = {
            id: itemId + '',
            pn: promoName,
            pi: promoId,
            pt: tmpPageType,
            wt: widgetType,
            p: price,
            datetime: new Date().toISOString()
        }

        if (promoHistoryJSON) {
            promoHistory = JSON.parse(promoHistoryJSON);
            const currentTimestamp = new Date().getTime();
            const existingRecordIndex = promoHistory.findIndex(record => record.id === itemId);

            if (existingRecordIndex !== -1) {
                promoHistory[existingRecordIndex] = data;
            } else {
                promoHistory.push(data);
            }

            promoHistory = promoHistory.filter(datetimeRecord => {
                const recordTimestamp = new Date(datetimeRecord.datetime).getTime();
                return currentTimestamp - recordTimestamp < 6 * 60 * 60 * 1000;
            });
        } else {
            promoHistory = [data];
        }

        const updatedPromoHistoryJSON = JSON.stringify(promoHistory);
        localStorage.setItem('_garderoboPromoHistory', updatedPromoHistoryJSON);
    }

    preparePromotionItemDetails(itemId, itemName, brand, category, price, quantity, promotionName, promotionId, creativeName, creativeSlot, locationId, event, widgetType) {
        if (event == GA4Events.SELECT_PROMO) {
            this.saveDataToLocalStorage(itemId, promotionName, promotionId, locationId, widgetType, price);
        }

        return {
            item_id: itemId,
            item_name: itemName,
            item_brand: brand,
            item_category: category,
            price: price,
            quantity: quantity,
            promotion_name: promotionName,
            promotion_id: promotionId,
            creative_name: creativeName,
            creative_slot: creativeSlot,
            location_id: locationId,
            item_list_id: promotionId + '_list',
            item_list_name: promotionName + ' list',
        }
    }

     get_id_by_offer_props(product, size) {
        if (product.offers_props_by_yml_id) {
            let obj = product.offers_props_by_yml_id;
            for (const key in obj) {
                for (const subkey in obj[key]) {
                    if ((subkey == 'custom_label_3') && (obj[key][subkey] == size))
                        return key;
                    if ((subkey == 'product_id') && (key == size))
                        return obj[key][subkey];
                }
            }
        }
        return size;
    }

    ga4ViewOrSelectPromotionVer2(products, widgetType, pageType, promoId, promoName, promoForEveryProduct, event, size) {
        let promotionsData = [];
        let promoNameId = promoId;

        let promoItems = [];
        for (var i=0; i < products.length; i++) {
            let product = products[i];
            if (!promoId)
                promoNameId = product.id;

            if (this.getSettings().promotions_version == 2) {
                if (size) {
                    let id = this.get_id_by_offer_props(product, size);

                    promoItems.push(
                        this.preparePromotionItemDetails(
                            id,
                            product.name,
                            product.brand,
                            product['category-name'],
                            product.price,
                            1,
                            `Garderobo - ${pageType} ${widgetType}`,
                            `garderobo_${pageType}_${widgetType}_${promoNameId}`,
                            `${promoName}`,
                            i + 1,
                            pageType,
                            event,
                            widgetType
                        )
                    )
                } else {
                    for (var j = 0; j < product.yml_ids.length; j++) {
                        let id = this.get_id_by_offer_props(product, product.yml_ids[j]);

                        promoItems.push(
                            this.preparePromotionItemDetails(
                                id,
                                product.name,
                                product.brand,
                                product['category-name'],
                                product.price,
                                1,
                                `Garderobo - ${pageType} ${widgetType}`,
                                `garderobo_${pageType}_${widgetType}_${promoNameId}`,
                                `${promoName}`,
                                i + 1,
                                pageType,
                                event,
                                widgetType
                            )
                        )
                    }
                }

                if (promoForEveryProduct) {
                    promotionsData.push(
                        this.preparePromotionDetails(
                            `garderobo_${pageType}_${widgetType}_${promoNameId}`,
                            `Garderobo - ${pageType} ${widgetType}`,
                            `${promoName}`,
                            i + 1,
                            pageType,
                            promoItems,
                            event
                        )
                    )

                    promoItems = [];
                }
            } else {
                let id = this.get_id_by_offer_props(product, product.yml_id);
                if (id == size) {
                    id = product.group_id;
                    if (!id)
                        id = product.wareId;
                }

                promoItems.push(
                    this.preparePromotionItemDetails(
                        id,
                        product.name,
                        product.brand,
                        product['category-name'],
                        product.price,
                        1,
                        `Garderobo - ${pageType} ${widgetType}`,
                        `garderobo_${pageType}_${widgetType}_${promoNameId}`,
                        `${promoName}`,
                        i + 1,
                        pageType,
                        event,
                        widgetType
                    )
                );

                if (promoForEveryProduct) {
                    promotionsData.push(
                        this.preparePromotionDetails(
                            `garderobo_${pageType}_${widgetType}_${promoNameId}`,
                            `Garderobo - ${pageType} ${widgetType}`,
                            `${promoName}`,
                            i + 1,
                            pageType,
                            promoItems,
                            event
                        )
                    )

                    promoItems = [];
                }
            }
        }

        if (!promoForEveryProduct) {
            promotionsData.push(
                this.preparePromotionDetails(
                    `garderobo_${pageType}_${widgetType}_${promoNameId}`,
                    `Garderobo - ${pageType} ${widgetType}`,
                    `${promoName}`,
                    1,
                    pageType,
                    promoItems,
                    event
                )
            )
        }

        for (var i=0; i < promotionsData.length; i++)
            this.sendGtmGA4Ver2(promotionsData[i], event);
    }

    /** google analytics 4 -
     * 1) При попадании в область видимости виджета рекомендации товаров (не луков) на странице товаров.
     * 2) При скроле товаров в виджете рекомендаций. Отправлять по одному товару, который попадает опять в область видимости.
     * 3) После открытия попапа луков. Отправлять товары, которые входят в состав лука.
     * 4) При пролистывании лука - отправлять новый массив товаров, который обновляется при скроле луков в попапе.
     * 5) При замене товара - отправлять только один товар, который заменили
     */
    ga4ViewItemList(items, widgetType) {
        const dataList = items.map((item, i) => ({
            item_name: item.name,
            item_id: item.id,
            price: item.price,
            item_brand: item.brand,
            item_category: item['category-name'],
            item_list_name: widgetType === WidgetType.LOOKS ? 'Garderobo - product looks' : 'Garderobo - product similar',
            index: (item.index || i) + 1,
            quantity: 1,
        }));

        //this.sendGtmGA4(dataList, GA4Events.VIEW_ITEM_LIST);
    }

    /** google analytics 4 -
     * 1) Клик по товару в виджете Рекомендованных товаров, который приводит к переходу на страницу товара.
     * 2) Клик по товару в попапе подбора луков, который приводит к переходу на страницу товара.
     * 3) При добавлении товара в корзину.
     */
    ga4SelectItem(items, widgetType, size = null) {
        let itemId = items[0].id;
        if (size)
            itemId = size;

        const dataList = items.map((item, i) => ({
            item_name: item.name,
            item_id: itemId,
            price: item.price,
            item_brand: item.brand,
            item_category: item['category-name'],
            item_list_name: widgetType === WidgetType.LOOKS ? 'Garderobo - product looks' : 'Garderobo - product similar',
            index: (item.index || i) + 1,
            quantity: 1,
        }));

        //this.sendGtmGA4(dataList, GA4Events.SELECT_ITEM);
    }

    getSettings() {
        return this.page.getWidgetParam('ga_settings') || {};
    }

    getGtmIdParam(item) {
        const idField = this.getSettings().id_field || null;
        return idField ? item[idField] : null;
    }

    getCurrencyCode() {
        return this.getSettings().currency_code || 'RUR';
    }

    sendGtmGA4(ecommerce, event, otherOptions = {}) {
        window.dataLayer = window.dataLayer || [];
        const dataLayerObj = {};

        if (ecommerce) {
            dataLayerObj.ecommerce = {};
            dataLayerObj.ecommerce.items = ecommerce;
        }

        if (event) {
            dataLayerObj.event = event;
        }

        Object.assign(dataLayerObj, otherOptions);

        dataLayer.push(dataLayerObj);

        console.log('_____________sendGtm ga4___________', dataLayerObj);
    }

    sendGtmGA4Ver2(promotion, event, otherOptions = {}) {
        window.dataLayer = window.dataLayer || [];
        const dataLayerObj = {};

        if (promotion)
            dataLayerObj.ecommerce = promotion;

        if (event) {
            dataLayerObj.event = event;
        }

        Object.assign(dataLayerObj, otherOptions);

        dataLayer.push(dataLayerObj);

        //console.log(JSON.stringify(dataLayerObj));
        //console.log('_____________sendGtm ga4V2___________', dataLayerObj);
    }

    sendGtm(ecommerce, eventAction, nonInteraction = 'False', otherOptions = {}) {
        const settings = this.getSettings();
        if (!settings.send_to_ga3)
            return false;

        window.dataLayer = window.dataLayer || [];
        const dataLayerObj = {};

        if (ecommerce) {
            dataLayerObj.ecommerce = ecommerce;
        }

        dataLayerObj.event = otherOptions.event || settings.ga_type || 'gtm-ee-event';

        const categoryLabel = settings['gtm-ee-event-category-label'] || 'gtm-ee-event-category';
        dataLayerObj[categoryLabel] = otherOptions.eventCategory || settings['gtm-ee-event-category'];

        const actionLanel = settings['gtm-ee-event-action-label'] || 'gtm-ee-event-action';
        dataLayerObj[actionLanel] = eventAction;

        if (settings['gtm-ee-event-non-interaction']) {
            dataLayerObj['gtm-ee-event-non-interaction'] = nonInteraction;
        }

        Object.assign(dataLayerObj, otherOptions);
        dataLayer.push(dataLayerObj);

        //console.log('_____________sendGtm___________', dataLayerObj);
    }
}
